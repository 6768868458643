import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import {  MAT_DIALOG_DATA , MatDialog, MatDialogRef} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { AssignedUser } from 'app/shared/models/tenant-info-models';
import { TenantUpdateAction } from 'app/features/tenant-manager/tenant-manager.model';

import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { selectTenantDialogTenantId } from '../../../tenant-dialog-user-general/tenant-dialog-general.selectors';
import { actionTenantDialogRepsRepIsCheckedUpdate, actionTenantDialogBatchRepsAddRequested, actionTenantDialogBatchRepsRemoveRequested, actionTenantDialogRepsUpdateRequested, actionTenantDialogRepOptionsRequested } from '../../tenant-dialog-tenant-rep.action';
import { selectTenantDialogRepsStateIsRepsLoaded, selectTenantDialogRepsStateRepOptions, selectTenantDialogRepsIsCreateUpdateSuccessfully, selectTenantDialogRepsIsRepSubmitting, selectTenantDialogRepsStateIsRepOptionsLoaded } from '../../tenant-dialog-tenant-rep.selector';
import { State } from 'app/features/tenant-dialog/tenant-dialog.state';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';


@Component({
  selector: 'dq-tenant-dialog-user-general-reps',
  templateUrl: './tenant-dialog-user-general-reps.component.html',
  styleUrls: ['./tenant-dialog-user-general-reps.component.scss', './tenant-dialog-user-general-reps.component.mobile.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TenantDialogUserGeneralRepsComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private dialogRef: MatDialogRef<TenantDialogUserGeneralRepsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef
  ) {
    //this.tenantIds.length > 0 means now this component is used for bacth tenants update (tenantIds will come from tenants manage page when tigger this dialog)
    if (data && data.action && data.tenantIds) {
      this.title = data.action.name
      this.action = data.action
      this.tenantIds = data.tenantIds
    }
  }

  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };
  title: string = 'Assign Representative'
  tenantIds: number[] = []

  action: TenantUpdateAction

  unsubscribe: Subject<void> = new Subject();
  repOptions$: Observable<AssignedUser[]>;
  repOptions: AssignedUser[];
  isRepLoaded$: Observable<boolean>
  repNameControl = new FormControl();
  repSearchOptions$: Observable<AssignedUser[]>
  type: string = '';
  panelOpenState = false;
  checkedRepList: AssignedUser[];
  isRepChecked: boolean;
  name: string;
  tenantId$: Observable<number>
  tenantId: number
  isMobile$: Observable<boolean>
  isMobile: boolean

  isRepOptionsLoaded$: Observable<boolean>
  isRepsSubmitting$: Observable<boolean>
  isUpdateSuccessfully$: Observable<boolean>

  ngOnInit(): void {
    this.tenantId$ = this.store.pipe(select(selectTenantDialogTenantId));
    this.store.dispatch(actionTenantDialogRepOptionsRequested())
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile))
    this.isRepOptionsLoaded$ = this.store.pipe(select(selectTenantDialogRepsStateIsRepOptionsLoaded));
    this.isRepsSubmitting$ = this.store.pipe(select(selectTenantDialogRepsIsRepSubmitting));
    this.isUpdateSuccessfully$ = this.store.pipe(select(selectTenantDialogRepsIsCreateUpdateSuccessfully));
    this.repOptions$ = this.store.pipe(select(selectTenantDialogRepsStateRepOptions));
    this.isRepLoaded$ = this.store.pipe(select(selectTenantDialogRepsStateIsRepsLoaded));
    this.repOptions$.pipe(takeUntil(this.unsubscribe)).subscribe(reps => {
      this.repOptions = reps;
      this.spinnerSaveButtonOptions.disabled = reps.length == 0
      //this.tenantIds.length > 0 means now this component is used for bacth tenants update (tenantIds will come from tenants manage page when tigger this dialog)
      if (this.tenantIds && this.tenantIds.length > 0) {
        this.spinnerSaveButtonOptions.disabled = reps.filter(rep => rep.isChecked).length == 0;
      }
      this.repSearchOptions$ = this.repNameControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
      this.cdr.markForCheck()
    })

    this.tenantId$.pipe(takeUntil(this.unsubscribe)).subscribe(tenantId => {
      this.tenantId = tenantId;
    })
    this.isRepsSubmitting$.pipe(takeUntil(this.unsubscribe)).subscribe(isRepsSubmitting => {
      this.spinnerSaveButtonOptions.active = isRepsSubmitting;
      this.cdr.markForCheck()
    })
    this.isUpdateSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isUpdateSuccessfully => {
      if (isUpdateSuccessfully) {
        this.close()
      }
    })


  }

  private _filter(value: string): AssignedUser[] {
    const filterValue = value.toLowerCase();
    return this.repOptions.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  changeSelection(checked, rep) {
    this.store.dispatch(actionTenantDialogRepsRepIsCheckedUpdate({ checked, rep }));
  }

  save() {
    if (this.spinnerSaveButtonOptions.active) {
      return
    }
    if (this.spinnerSaveButtonOptions.disabled) {
      return
    }
    let reps = this.repOptions.filter(rep => rep.isChecked);
    let id = this.tenantId

    if (this.tenantIds && this.tenantIds.length > 0) {
      if (this.action.action == 'addRepresentatives') {
        this.store.dispatch(actionTenantDialogBatchRepsAddRequested({ tenantIds: this.tenantIds, repIds: reps.map(rep => rep.id) }))
      } else if (this.action.action == 'removeRepresentatives') {
        this.store.dispatch(actionTenantDialogBatchRepsRemoveRequested({ tenantIds: this.tenantIds, repIds: reps.map(rep => rep.id) }))
      }
    } else {
      this.store.dispatch(actionTenantDialogRepsUpdateRequested({ id, reps }))
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

}

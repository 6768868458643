import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import {  MAT_DIALOG_DATA , MatDialog, MatDialogRef} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { Tag } from 'app/shared/models/tenant-info-models';
import { TenantUpdateAction } from 'app/features/tenant-manager/tenant-manager.model';

import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { selectTenantDialogTenantId } from '../../../tenant-dialog-user-general/tenant-dialog-general.selectors';
import { actionTenantDialogTagsTagIsCheckedUpdate, actionTenantDialogBatchTagsAddRequested, actionTenantDialogBatchTagsRemoveRequested, actionTenantDialogTagsUpdateRequested, actionTenantDialogTagOptionsRequested } from '../../tenant-dialog-tenant-tag.action';
import { selectTenantDialogTagsStateIsTagOptionssLoaded, selectTenantDialogTagsIsTagSubmitting, selectTenantDialogTagsIsCreateUpdateSuccessfully, selectTenantDialogTagsStateTagOptions, selectTenantDialogTagsStateIsTagsLoaded } from '../../tenant-dialog-tenant-tag.selector';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-tenant-dialog-user-general-tags',
  templateUrl: './tenant-dialog-user-general-tags.component.html',
  styleUrls: ['./tenant-dialog-user-general-tags.component.scss', './tenant-dialog-user-general-tags.component.mobile.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TenantDialogUserGeneralTagsComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<TenantDialogUserGeneralTagsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef
  ) {
    //this.tenantIds.length > 0 means now this component is used for bacth tenants update (tenantIds will come from tenants manage page when tigger this dialog)
    if (data && data.action && data.tenantIds) {
      this.title = data.action.name
      this.action = data.action
      this.tenantIds = data.tenantIds
    }
  }

  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  action: TenantUpdateAction
  title: string = 'Add Tag'
  tenantIds: number[] = []
  unsubscribe: Subject<void> = new Subject();
  tagOptions$: Observable<Tag[]>;
  tagOptions: Tag[];
  isTagLoaded$: Observable<boolean>
  tagNameControl = new FormControl();
  tagSearchOptions$: Observable<Tag[]>
  type: string = '';
  panelOpenState = false;
  checkedTagList: Tag[];
  isTagChecked: boolean;
  name: string;
  tenantId$: Observable<number>
  tenantId: number
  isMobile$: Observable<boolean>
  isMobile: boolean

  isTagOptionsLoaded$: Observable<boolean>
  isTagsSubmitting$: Observable<boolean>
  isUpdateSuccessfully$: Observable<boolean>

  ngOnInit(): void {
    this.tenantId$ = this.store.pipe(select(selectTenantDialogTenantId));
    this.isTagOptionsLoaded$ = this.store.pipe(select(selectTenantDialogTagsStateIsTagOptionssLoaded));
    this.isTagsSubmitting$ = this.store.pipe(select(selectTenantDialogTagsIsTagSubmitting));
    this.isUpdateSuccessfully$ = this.store.pipe(select(selectTenantDialogTagsIsCreateUpdateSuccessfully));
    this.tagOptions$ = this.store.pipe(select(selectTenantDialogTagsStateTagOptions));
    this.isTagLoaded$ = this.store.pipe(select(selectTenantDialogTagsStateIsTagsLoaded));
    this.store.dispatch(actionTenantDialogTagOptionsRequested())
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile))
    this.tagOptions$.pipe(takeUntil(this.unsubscribe)).subscribe(tags => {
      this.tagOptions = tags;
      this.spinnerSaveButtonOptions.disabled = tags.length == 0
      //this.tenantIds.length > 0 means now this component is used for bacth tenants update (tenantIds will come from tenants manage page when tigger this dialog)
      if (this.tenantIds && this.tenantIds.length > 0) {
        this.spinnerSaveButtonOptions.disabled = tags.filter(tag => tag.isChecked).length == 0;

      }
      this.cdr.markForCheck()
    })

    this.tenantId$.pipe(takeUntil(this.unsubscribe)).subscribe(tenantId => {
      this.tenantId = tenantId;
    })
    this.isTagsSubmitting$.pipe(takeUntil(this.unsubscribe)).subscribe(isTagsSubmitting => {
      this.spinnerSaveButtonOptions.active = isTagsSubmitting;
      this.cdr.markForCheck()
    })
    this.isUpdateSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isUpdateSuccessfully => {
      if (isUpdateSuccessfully) {
        this.close()
      }
    })

    this.tagSearchOptions$ = this.tagNameControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );


  }

  private _filter(value: string): Tag[] {
    const filterValue = value.toLowerCase();
    return this.tagOptions.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  changeSelection(checked, tag) {
    this.store.dispatch(actionTenantDialogTagsTagIsCheckedUpdate({ checked, tag }));
  }

  save() {
    if (this.spinnerSaveButtonOptions.active) {
      return
    }
    if (this.spinnerSaveButtonOptions.disabled) {
      return
    }
    let tags = this.tagOptions.filter(tag => tag.isChecked);
    let id = this.tenantId
    if (this.tenantIds && this.tenantIds.length > 0) {
      if (this.action.action == 'addTags') {
        this.store.dispatch(actionTenantDialogBatchTagsAddRequested({ tenantIds: this.tenantIds, tagIds: tags.map(tag => tag.id) }))
      } else if (this.action.action == 'removeTags') {
        this.store.dispatch(actionTenantDialogBatchTagsRemoveRequested({ tenantIds: this.tenantIds, tagIds: tags.map(tag => tag.id) }))
      }
    } else {
      this.store.dispatch(actionTenantDialogTagsUpdateRequested({ id: id, tags: tags }))
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

}

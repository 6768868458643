/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';
import { Tag } from 'app/shared/models/lead-info-models';
import { LeadUpdateAction } from 'app/features/lead-manager/lead-manager.model';

import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { selectClientLeadId } from '../../../client-dialog-user-general/client-dialog-general.selectors';
import { actionClientDialogTagsTagIsCheckedUpdate, actionClientDialogBatchTagsAddRequested, actionClientDialogBatchTagsRemoveRequested, actionClientDialogTagsUpdateRequested, actionClientDialogTagOptionsRequested } from '../../client-dialog-lead-tag.action';
import { selectclientDialogTagsStateIsTagOptionssLoaded, selectclientDialogTagsIsTagSubmitting, selectclientDialogTagsIsCreateUpdateSuccessfully, selectclientDialogTagsStateTagOptions, selectclientDialogTagsStateIsTagsLoaded } from '../../client-dialog-lead-tag.selector';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-user-general-tags',
  templateUrl: './client-dialog-user-general-tags.component.html',
  styleUrls: ['./client-dialog-user-general-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogUserGeneralTagsComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<ClientDialogUserGeneralTagsComponent>,
    @Inject('dialogData') private dialogData: any,
    private cdr: ChangeDetectorRef
  ) {
    if (dialogData && dialogData.action && dialogData.leadIds) {
      this.title = dialogData.action.name
      this.action = dialogData.action
      this.leadIds = dialogData.leadIds
    }
  }

  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  action: LeadUpdateAction
  title: string = 'Add Tag'
  leadIds: number[] = []
  unsubscribe: Subject<void> = new Subject();
  tagOptions$: Observable<Tag[]>;
  tagOptions: Tag[];
  isTagLoaded$: Observable<boolean>
  tagNameControl = new UntypedFormControl();
  tagSearchOptions$: Observable<Tag[]>
  type: string = '';
  panelOpenState = false;
  checkedTagList: Tag[];
  isTagChecked: boolean;
  name: string;
  leadId$: Observable<number>
  leadId: number
  isMobile$: Observable<boolean>
  isMobile: boolean

  isTagOptionsLoaded$: Observable<boolean>
  isTagsSubmitting$: Observable<boolean>
  isUpdateSuccessfully$: Observable<boolean>

  ngOnInit(): void {
    this.leadId$ = this.store.pipe(select(selectClientLeadId));
    this.isTagOptionsLoaded$ = this.store.pipe(select(selectclientDialogTagsStateIsTagOptionssLoaded));
    this.isTagsSubmitting$ = this.store.pipe(select(selectclientDialogTagsIsTagSubmitting));
    this.isUpdateSuccessfully$ = this.store.pipe(select(selectclientDialogTagsIsCreateUpdateSuccessfully));
    this.tagOptions$ = this.store.pipe(select(selectclientDialogTagsStateTagOptions));
    this.isTagLoaded$ = this.store.pipe(select(selectclientDialogTagsStateIsTagsLoaded));
    this.store.dispatch(actionClientDialogTagOptionsRequested())
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize))
    this.tagOptions$.pipe(takeUntil(this.unsubscribe)).subscribe(tags => {
      this.tagOptions = tags;
      this.spinnerSaveButtonOptions.disabled = tags.length == 0
      if (this.leadIds && this.leadIds.length > 0) {
        this.spinnerSaveButtonOptions.disabled = tags.filter(tag => tag.isChecked).length == 0;

      }
      this.cdr.markForCheck()
    })

    this.leadId$.pipe(takeUntil(this.unsubscribe)).subscribe(leadId => {
      this.leadId = leadId;
    })
    this.isTagsSubmitting$.pipe(takeUntil(this.unsubscribe)).subscribe(isTagsSubmitting => {
      this.spinnerSaveButtonOptions.active = isTagsSubmitting;
      this.cdr.markForCheck()
    })
    this.isUpdateSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isUpdateSuccessfully => {
      if (isUpdateSuccessfully) {
        this.dialogRef.close()
      }
    })

    this.tagSearchOptions$ = this.tagNameControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): Tag[] {
    const filterValue = value.toLowerCase();
    return this.tagOptions.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  changeSelection(checked, tag) {
    this.store.dispatch(actionClientDialogTagsTagIsCheckedUpdate({ checked, tag }));
  }

  save() {
    if (this.spinnerSaveButtonOptions.active) {
      return
    }
    if (this.spinnerSaveButtonOptions.disabled) {
      return
    }
    const tags = this.tagOptions.filter(tag => tag.isChecked);
    const id = this.leadId
    if (this.leadIds && this.leadIds.length > 0) {
      if (this.action.action == 'addTags') {
        this.store.dispatch(actionClientDialogBatchTagsAddRequested({ leadIds: this.leadIds, tagIds: tags.filter(tag => !tag.isPreDefined).map(tag => tag.id), preDefinedTagIds: tags.filter(tag => tag.isPreDefined).map(tag => tag.id) }))
      } else if (this.action.action == 'removeTags') {
        this.store.dispatch(actionClientDialogBatchTagsRemoveRequested({ leadIds: this.leadIds, tagIds: tags.filter(tag => !tag.isPreDefined).map(tag => tag.id), preDefinedTagIds: tags.filter(tag => tag.isPreDefined).map(tag => tag.id) }))
      }
    } else {
      this.store.dispatch(actionClientDialogTagsUpdateRequested({ id: id, tags: tags.filter(tag => !tag.isPreDefined), preDefinedTags: tags.filter(tag => tag.isPreDefined) }))
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
}

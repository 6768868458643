<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="dialog-container">
    <div class="doc-dialog-title">
        <div class="right-button-div">
            <div (click)="$event.preventDefault(); close()">
                <mat-icon svgIcon="times" class="icon cancel-button"></mat-icon>
            </div>
        </div>
    </div>
    <div class="layout-container dq-row" #target>
        <ng-container *ngIf="(isFormViewerLoading$ | async) == false; else formLoading">
            <ng-container *ngIf="documents$ | async as documents">
                <ng-container *ngIf="documents.length > 0;else noFile">
                    <ng-container *ngFor="let document of documents">
                        <ng-container *ngIf="document.fileType == 'pdf'">
                            <ngx-extended-pdf-viewer class="pdf-viewer" [src]="document.documentUrl"
                                [useBrowserLocale]="true" [textLayer]="true" [showHandToolButton]="true"
                                [minifiedJSLibraries]="false">
                            </ngx-extended-pdf-viewer>
                        </ng-container>
                        <ng-container *ngIf="isBase64Image(document.fileType)">
                            <div class="image-container">
                                <img [src]="document.documentUrl" alt="Red dot" />
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-template #noFile>
            <div class="error-message dq-flex-centered">
                File not available!
            </div>
        </ng-template>
        <ng-template #formLoading>
            <div class="spinner-div dq-flex-centered">
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>
    </div>
</div>
<div class="client-appintment-cancel-dialog-component">
    <div class="desktop-container">
        <div class="confirm-dialog-container">
            <div class="mat-dialog-title">
                <h2>
                    Lost Details
                </h2>
                <div (click)="$event.preventDefault();close()">
                    <mat-icon svgIcon="times" class="icon cancel"></mat-icon>
                </div>
            </div>
            <div class="mat-dialog-content">
                <form [formGroup]="lostForm">
                    <div class="form-container">
                        <mat-form-field appearance="outline">
                            <mat-label>Lost Reason</mat-label>
                            <mat-select formControlName="lostReason" disabled>
                                <mat-option *ngFor="let reason of (lostReasonOptions$ | async)" [value]="reason">
                                    {{reason}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Comments</mat-label>
                            <textarea matInput formControlName="comments" cdkTextareaAutosize required
                                cdkAutosizeMinRows="10" cdkAutosizeMaxRows="15" placeholder="Enter comments"
                                maxlength="2000"></textarea>
                        </mat-form-field>
                    </div>
                </form>
            </div>
            <div class="row">
                <div class="col-6" style="text-align: center;">
                    <dq-spinner-button class="generic-button confirm-button" (click)="confirm()" type="button"
                        [options]="spinnerButtonOptions"></dq-spinner-button>
                </div>
                <div class="col-6" style="text-align: center;">
                    <button (click)="cancel()" mat-button class="generic-button cancel-button">
                        Cancel
                        <!-- <mat-icon svgIcon="map-marker-alt" icon="map-marker-alt"></mat-icon> -->
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { SESSION_USER_ROLES } from 'app/core/auth/auth.models';
import { RouterLink } from 'app/shared/models/router-link.model';
import { INavCard } from 'app/shared/components/nav-card/nav-card.model';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { selectUserRoles } from 'app/core/auth/auth.selectors';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';

@Component({
  selector: 'dq-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LandingComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private store: Store,) { }

  unsubscribe: Subject<void> = new Subject();

  isMobile$: Observable<boolean>;
  isMobile: boolean;
  translationPath = 'dq.user-profile.overview.';
  cards: INavCard[] = [
    {
      title: 'Live',
      icon: 'timeline',
      description: 'Live Dashboard',
      links: [
        new RouterLink(
          '/dashboard-user/live-data',
          'Reach the live dashboard'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.UserLiveManager]
    },
    {
      title: 'Dashboard',
      icon: 'dashboard',
      description: 'Dashboard',
      links: [
        new RouterLink(
          '/dashboard',
          'Manage appointments, deals and monthly reports'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesAppointmentManager, SESSION_USER_ROLES.SalesAppointmentUser]
    },
    {
      title: 'Booking',
      icon: 'calendar_today',
      description: 'Booking',
      links: [
        new RouterLink(
          '/booking',
          'Consult the bookings hub'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesBookingManager]
    },
    {
      title: 'Representative Scheduler',
      icon: 'schedule',
      description: 'Representative Scheduler',
      links: [
        new RouterLink(
          '/representative-scheduler',
          'Consult representative schedules'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.Representative, SESSION_USER_ROLES.SalesScheduleManager]
    },
    {
      title: 'Lead Manager',
      icon: 'track_changes',
      description: 'Lead Manager',
      links: [
        new RouterLink(
          '/lead-manager',
          'Search and manage leads'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesLeadManager, SESSION_USER_ROLES.SalesLeadUser]
    },
    {
      title: 'Tenant Manager',
      icon: 'people',
      description: 'Tenant Manager',
      links: [
        new RouterLink(
          '/tenant-manager',
          'Go to tenant manager'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin]
    },
    {
      title: 'Service Request Manager',
      icon: 'room_service',
      description: 'Service Request Manager',
      links: [
        new RouterLink(
          '/service-request-manager',
          'Go to service request manager'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.ServiceCoordinator]
    },
    {
      title: 'Inbox',
      icon: 'mail',
      description: 'Inbox',
      links: [
        new RouterLink(
          '/unified-inbox',
          'Keep up to date with your communications'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesUnifiedInboxManager]
    },
    {
      title: 'Tenant Inbox',
      icon: 'chat_bubble',
      description: 'Tenant Inbox',
      links: [
        new RouterLink(
          '/tenant-unified-inbox',
          'Keep up to date with tenant communications'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesUnifiedInboxManager]
    },
    {
      title: 'User Schedule',
      icon: 'perm_contact_calendar',
      description: 'User Schedule',
      links: [
        new RouterLink(
          '/user-schedule',
          'Go to user schedule'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesScheduleManager]
    },
    {
      title: 'Buildings',
      icon: 'location_city',
      description: 'Buildings',
      links: [
        new RouterLink(
          '/building',
          'Consult the buildings'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.BuildingManager]
    },
    {
      title: 'Users',
      icon: 'person_add',
      description: 'Users',
      links: [
        new RouterLink(
          '/user-account',
          'Add, edit and remove users'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.UserManager]
    },
    {
      title: 'Inventory',
      icon: 'business',
      description: 'Inventory',
      links: [
        new RouterLink(
          '/inventory',
          'Consult the property inventory'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesInventoryUser]
    },
    {
      title: 'Tasks',
      icon: 'list',
      description: 'Tasks',
      links: [
        new RouterLink(
          '/tasks',
          "Consult overdue, upcoming and today's tasks"
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.TaskManager, SESSION_USER_ROLES.TaskUser]
    },
    {
      title: 'Profile',
      icon: 'account_circle',
      description: 'Profile',
      links: [
        new RouterLink(
          '/user-profile',
          'Change your profile and account settings'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.User]
    },
    {
      title: 'Queue Settings',
      icon: 'settings_applications',
      description: 'Queue Settings',
      links: [
        new RouterLink(
          '/account-settings',
          'Go to account settings'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin],
    },
    {
      title: 'Merchant Account',
      icon: 'work',
      description: 'Merchant Account',
      links: [
        new RouterLink(
          '/merchant-account',
          'Go to merchant account'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.MerchantAccountManager],
    },
    {
      title: 'AI Simulation',
      icon: 'format_shapes',
      description: 'AI Simulation',
      links: [
        new RouterLink(
          '/ai-simulation',
          'go to AI simulation'
        )
      ],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.MerchantAccountManager],
    }
  ];

  ngOnInit(): void {
    this.store.pipe(select(selectUserRoles), takeUntil(this.unsubscribe)).subscribe(roles => {
      if (roles && roles.length > 0) {
        this.cards = this.cards.filter(x =>
          roles.some(role => x.roles.includes(role))
        );
      }
      else {
        this.cards = [];
      }
    })

    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize));
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
      if (isMobile) {
        this.router.navigate(['/dashboard/appointments']);
      }
    })

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

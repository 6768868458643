/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Action, Store } from '@ngrx/store';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { Observable, Subject } from 'rxjs';
import { selectClientDialogIsClientRentalApplicationFormViewerDocument, selectClientDialogIsClientRentalApplicationFormViewerLoading } from '../../client-dialog-rental-application.selector';
import { actionClientDialogApplicationRentalPdfViewerReset } from '../../client-dialog-rental-application.action';
import { RentalDocument } from 'app/features/client-dialog/client-dialog-model';
import { IMAGE_EXTENSION } from 'app/shared/models/image.model';

@Component({
  selector: 'dq-client-dialog-rental-application-doc-viewer',
  templateUrl: './client-dialog-rental-application-doc-viewer.component.html',
  styleUrls: ['./client-dialog-rental-application-doc-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogRentalApplicationDocViewerComponent implements OnInit, OnDestroy {

  constructor(private store: Store<State>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ClientDialogRentalApplicationDocViewerComponent>) {
    this.action = data.action as Action;
  }

  unsubscribe$: Subject<void> = new Subject<void>();

  isFormViewerLoading$: Observable<boolean>;
  documents$: Observable<RentalDocument[]>;
  action: Action;

  extensions = IMAGE_EXTENSION;

  ngOnInit(): void {
    this.isFormViewerLoading$ = this.store.select(selectClientDialogIsClientRentalApplicationFormViewerLoading);
    this.documents$ = this.store.select(selectClientDialogIsClientRentalApplicationFormViewerDocument);
    this.store.dispatch(this.action);
  }

  getSafeImage(document: string, fileType: string): string {
    return `data:image/${fileType};base64,${document}`
  }

  isBase64Image(type: string): boolean {
    return this.extensions.includes(type);
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(actionClientDialogApplicationRentalPdfViewerReset());
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectScreenManagerIsMobileSize } from 'app/core/screen-manager/screen-manager.selectors';
import { State } from 'app/core/screen-manager/screen-manager.state';
import { LeadEmailMessage } from 'app/shared/models/lead-communication.model';
import { Observable } from 'rxjs';

import { ITimelineElement, TIMELINE_TYPES } from '../timeline.model';

@Component({
  selector: 'dq-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss', './timeline.component.mobile.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineComponent implements OnInit {
  constructor(public store: Store<State>) { }

  @Input() events: ITimelineElement[] = []

  @Input() displayEmailReply: boolean = false;

  @Output()
  reply = new EventEmitter<LeadEmailMessage>();

  @Output()
  replyAll = new EventEmitter<LeadEmailMessage>();

  @Output()
  forward = new EventEmitter<LeadEmailMessage>();

  isMobile$: Observable<boolean>;

  timeline_types = TIMELINE_TYPES;

  replyEmail(emailMessage: LeadEmailMessage) {
    this.reply.emit(emailMessage);
  }

  replyEmailAll(emailMessage: LeadEmailMessage) {
    this.replyAll.emit(emailMessage);
  }

  forwardEmail(emailMessage: LeadEmailMessage) {
    this.forward.emit(emailMessage);
  }


  ngOnInit(): void {
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobileSize));
  }
}

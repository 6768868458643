import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef, OnDestroy, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { selectUserId, selectUserTimeZone } from 'app/core/auth/auth.selectors';
import { ClientDialogService } from 'app/features/client-dialog/client-dialog.service';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { TaskAssignUser, TaskCreateParam, TaskReminderTimeBeforeOption, TaskReminderType, TaskType, TASK_REMINDER_TIME_BEFORE_OPTION, TASK_REMINDER_TYPES, TASK_TYPES } from 'app/shared/models/task.model';
import { TimeZone, TIME_ZONES } from 'app/shared/models/timezone.model';

import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { actionClientDialogTasktaskCreateRequested, clientDialogTaskActions } from '../../client-dialog-task.action';
import { selectClientDialogTaskAssignUsers, selectClientDialogTaskIsTaskCreatedSuccessfully, selectClientDialogTaskIsTaskCreating } from '../../client-dialog-task.selector';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-task-create-dialog',
  templateUrl: './client-dialog-task-create-dialog.component.html',
  styleUrls: ['./client-dialog-task-create-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogTaskCreateDialogComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ClientDialogTaskCreateDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject('dialogData') private dialogData: any
  ) {
    if (dialogData.leadId) {
      this.leadId = dialogData.leadId
    }
  }

  leadId: number;

  dashQTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#70d080'
    },
    dial: {
      dialBackgroundColor: '#70d080',
    },
    clockFace: {
      clockFaceBackgroundColor: '#f0f0f0',
      clockHandColor: '#70d080',
      clockFaceTimeInactiveColor: '#555'
    }
  };

  spinnerCreateButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Create',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  unsubscribe: Subject<void> = new Subject();

  taskTypes: TaskType[] = TASK_TYPES
  taskReminderTypes: TaskReminderType[] = TASK_REMINDER_TYPES
  taskReminderTimeBeforeOptions: TaskReminderTimeBeforeOption[] = TASK_REMINDER_TIME_BEFORE_OPTION

  isCreating$: Observable<boolean>
  isCreatedSuccessfully$: Observable<boolean>

  taskAssignUsers$: Observable<TaskAssignUser[]>

  today: Date = new Date()

  timeZoneOptions: TimeZone[] = TIME_ZONES

  userTimeZone$: Observable<string>
  userId$: Observable<number>
  
  taskCreateForm: UntypedFormGroup = this.formBuilder.group({
    taskName: new UntypedFormControl('', [Validators.required]),
    taskType: new UntypedFormControl('', [Validators.required]),
    leadId: new UntypedFormControl('', [Validators.required]),
    userId: new UntypedFormControl('', [Validators.required]),
    date: new UntypedFormControl('', [Validators.required]),
    time: new UntypedFormControl('', [Validators.required]),
    timeZone: new UntypedFormControl('', [Validators.required]),
    taskReminderType: new UntypedFormControl('Email', [Validators.required]),
    taskReminderTimeBefore: new UntypedFormControl(30, [Validators.required]),
  })

  ngOnInit(): void {

    this.taskCreateForm.get('leadId').setValue(this.leadId);

    this.store.dispatch(clientDialogTaskActions.actionClientDialogTasktaskAssignUsersRequested())
    this.isCreating$ = this.store.pipe(select(selectClientDialogTaskIsTaskCreating))
    this.isCreatedSuccessfully$ = this.store.pipe(select(selectClientDialogTaskIsTaskCreatedSuccessfully))
    this.taskAssignUsers$ = this.store.pipe(select(selectClientDialogTaskAssignUsers))

    this.isCreating$.pipe(takeUntil(this.unsubscribe)).subscribe(isCreating => {
      this.spinnerCreateButtonOptions.active = isCreating
      this.cdr.markForCheck()
    })

    this.isCreatedSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isCreatedSuccessfully => {
      if (isCreatedSuccessfully) {
        this.dialogRef.close(true);
      }
    })

    this.userTimeZone$ = this.store.pipe(select(selectUserTimeZone))
    this.userId$ = this.store.pipe(select(selectUserId))

    this.userTimeZone$.pipe(takeUntil(this.unsubscribe)).subscribe(userTimeZone => {
      if (userTimeZone && TIME_ZONES.find(x => x.id == userTimeZone)) {
        this.taskCreateForm.get('timeZone').setValue(userTimeZone)
      }
    })

    this.userId$.pipe(takeUntil(this.unsubscribe)).subscribe(userId => {
      this.taskCreateForm.get('userId').setValue(userId)
    })
  }

  create() {
    if (this.spinnerCreateButtonOptions.active) {
      return
    }
    this.taskCreateForm.markAllAsTouched()
    if (this.taskCreateForm.invalid) {
      return
    }
    let param: TaskCreateParam = {
      taskName: this.taskCreateForm.get('taskName').value,
      leadId: this.taskCreateForm.get('leadId').value,
      taskType: this.taskCreateForm.get('taskType').value,
      userId: this.taskCreateForm.get('userId').value,
      date: this.taskCreateForm.get('date').value,
      time: this.taskCreateForm.get('time').value,
      timeZone: this.taskCreateForm.get('timeZone').value,
      taskReminderType: this.taskCreateForm.get('taskReminderType').value,
      taskReminderTimeBefore: this.taskCreateForm.get('taskReminderTimeBefore').value,
    }
    this.store.dispatch(actionClientDialogTasktaskCreateRequested({ param }))
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.store.dispatch(clientDialogTaskActions.actionClientDialogTaskTaskCreateStateReset())
  }
}

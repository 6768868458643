/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { State } from 'app/features/client-dialog/client-dialog.state';
import { takeUntil } from 'rxjs/operators';
import { ClientDialogMatDialogService } from 'app/features/client-dialog/client-dialog-mat-dialog.serivce';
import { RentalFormTemplate } from 'app/features/client-dialog/client-dialog-model';
import { selectClientDialogIsClientRentalApplicationAddNewFormLoading, selectClientDialogIsClientRentalApplicationAddNewFormSending, selectClientDialogIsClientRentalFormTemplates } from '../../client-dialog-rental-application.selector';
import { actionClientDialogApplicationRentalApplicationAddNewFormRequested, actionClientDialogApplicationRentalApplicationAdditionalFormTemplatesRequested, actionClientDialogApplicationRentalApplicationFormTemplatesRequested, actionClientDialogApplicationRentalRequestAddNewFormRequested, actionClientDialogApplicationRentalRequestFormTemplatesRequested } from '../../client-dialog-rental-application.action';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-rental-application-form-add-new',
  templateUrl: './client-dialog-rental-application-form-add-new.component.html',
  styleUrls: ['./client-dialog-rental-application-form-add-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogRentalApplicationFormAddNewComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private clientDialogMatDialogService: ClientDialogMatDialogService,
    @Inject('dialogData') private dialogData: any,
    private cdr: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder) {
    this.formType = dialogData.formType;
    this.rentalRequestId = dialogData.rentalRequestId;
    this.rentalApplicationId = dialogData.rentalApplicationId;
    this.addNewForm = this.formBuilder.group({
      formTemplates: new FormArray([])
    });
  }

  unsubscribe$: Subject<void> = new Subject<void>();
  addNewForm: UntypedFormGroup;

  formType: string;
  rentalRequestId: number;
  rentalApplicationId: number;
  rentalFormTemplates: RentalFormTemplate[]
  rentalFormTemplates$: Observable<RentalFormTemplate[]>
  isRentalApplicationAddNewFormLoading$: Observable<boolean>;

  countryCode: string;

  spinnerAddNewFormButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Add',
    flat: true,    spinnerSize: 18,

    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  ngOnInit(): void {

    this.store.select(selectClientDialogIsClientRentalApplicationAddNewFormLoading);

    this.isRentalApplicationAddNewFormLoading$ = this.store.select(selectClientDialogIsClientRentalApplicationAddNewFormLoading);

    if (this.formType == 'rentalRequest') {
      this.store.dispatch(actionClientDialogApplicationRentalRequestFormTemplatesRequested({ rentalRequestId: this.rentalRequestId }));
    }
    else if (this.formType == 'rentalApplication') {
      this.store.dispatch(actionClientDialogApplicationRentalApplicationFormTemplatesRequested({ rentalApplicationId: this.rentalApplicationId }));
    }
    else if (this.formType == 'rentalApplicationAdditionalForm') {
      this.store.dispatch(actionClientDialogApplicationRentalApplicationAdditionalFormTemplatesRequested({ rentalApplicationId: this.rentalApplicationId }));
    }

    this.rentalFormTemplates$ = this.store.select(selectClientDialogIsClientRentalFormTemplates);
    this.rentalFormTemplates$.pipe(takeUntil(this.unsubscribe$)).subscribe(rentalFormTemplates => {
      this.rentalFormTemplates = rentalFormTemplates;
      if (rentalFormTemplates.length > 0) {
        this.addNewForm = this.formBuilder.group({
          formTemplates: new FormArray([])
        });
        this.rentalFormTemplates.forEach(() => this.formTemplateFormArray.push(new FormControl(false)));
        this.cdr.markForCheck();
      }
    });

    this.store.select(selectClientDialogIsClientRentalApplicationAddNewFormSending).pipe(takeUntil(this.unsubscribe$)).subscribe(isSending => {
      this.spinnerAddNewFormButtonOptions.active = isSending;
    });
  }

  get formTemplateFormArray() {
    return this.addNewForm.controls.formTemplates as FormArray;
  }

  getVersionLanguageLabel(versionLanguage: string) {
    if (versionLanguage == 'en') {
      return 'English';
    }
    else if (versionLanguage == 'fr') { 
      return 'French';
    }
    else {
      return 'Unknown Language';
    }
  }

  isFormSelected(id: number) {
    if (this.addNewForm.get('formTemplates').value[id] == true) {
      return 'form-item-selected';
    }
  }

  submitForm() {
    if (this.addNewForm.valid && !this.spinnerAddNewFormButtonOptions.active) {
      const selectedOrderIds = this.addNewForm.value.formTemplates
        .map((checked, i) => checked ? this.rentalFormTemplates[i].id : null)
        .filter(v => v !== null) as number[];

      if (this.formType == 'rentalRequest') {
        this.store.dispatch(actionClientDialogApplicationRentalRequestAddNewFormRequested({ rentalRequestId: this.rentalRequestId, formIds: selectedOrderIds }));
      }
      else if (this.formType == 'rentalApplication' || this.formType == 'rentalApplicationAdditionalForm') {
        this.store.dispatch(actionClientDialogApplicationRentalApplicationAddNewFormRequested({ rentalApplicationId: this.rentalApplicationId, formIds: selectedOrderIds }));
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { State } from 'app/features/client-dialog/client-dialog.state';
import { takeUntil } from 'rxjs/operators';
import { ClientDialogMatDialogService } from 'app/features/client-dialog/client-dialog-mat-dialog.serivce';
import { selectClientDialogIsRentalApplicationCancelling, selectClientDialogRentalApplicationCancelErrorMessage } from '../../client-dialog-rental-application.selector';
import { clientDialogApplicationRentalApplicationActions } from '../../client-dialog-rental-application.action';
import { selectClientLeadId } from '../../../client-dialog-user-general/client-dialog-general.selectors';
import { REQUEST_CANCEL_REASON_TYPES } from 'app/shared/models/rental-application.model';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-rental-application-cancel-request',
  templateUrl: './client-dialog-rental-application-cancel-request.component.html',
  styleUrls: ['./client-dialog-rental-application-cancel-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogRentalApplicationCancelRequestComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private clientDialogMatDialogService: ClientDialogMatDialogService,
    @Inject('dialogData') private dialogData: any,
    public fb: UntypedFormBuilder)
  {
      this.rentalRequestId = dialogData.rentalRequestId;
  }

  unsubscribe$: Subject<void> = new Subject<void>();
  cancelRequestFormGroup: UntypedFormGroup;
  rentalRequestId: number;
  leadId: number;
  errorMessage$: Observable<string>;

  countryCode: string;

  REQUEST_CANCEL_REASON_TYPES = REQUEST_CANCEL_REASON_TYPES;

  spinnerCancelButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Confirm',
    flat: true,
    buttonColor: 'warn',
    spinnerColor: 'accent',
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  ngOnInit(): void {

    this.store.dispatch(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalResetCancelErrorMessage());

    this.cancelRequestFormGroup = this.fb.group({
      reasonType: ['', Validators.required],
      reason: ['', Validators.required],
    });

    this.store.select(selectClientLeadId).pipe(takeUntil(this.unsubscribe$)).subscribe(leadId => {
      this.leadId = leadId;
    });

    this.store.select(selectClientDialogIsRentalApplicationCancelling).pipe(takeUntil(this.unsubscribe$)).subscribe(isDeleting => {
      this.spinnerCancelButtonOptions.active = isDeleting;
    });
    this.errorMessage$ = this.store.select(selectClientDialogRentalApplicationCancelErrorMessage);
  }

  cancelRentalRequest() {
    if(this.cancelRequestFormGroup.valid && !this.spinnerCancelButtonOptions.active) {
      this.store.dispatch(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestCancelRequested({
        reasonType: this.cancelRequestFormGroup.get('reasonType').value,
        reason: this.cancelRequestFormGroup.get('reason').value,
        rentalRequestId: this.rentalRequestId,
        leadId: this.leadId
      }));
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

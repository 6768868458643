<div class="timeline-component">
  <ng-container *ngIf="!(isMobile$ | async); else mobile">
    <div class="desktop-container">
      <section>
        <article *ngFor="let event of events">
          <ng-container [ngSwitch]="event.type">
            <dq-timeline-appointment *ngSwitchCase="'Appointment'" [event]="event">
            </dq-timeline-appointment>
            <dq-timeline-note *ngSwitchCase="'Note'" [event]="event">
            </dq-timeline-note>
            <dq-timeline-voice *ngSwitchCase="'Voice'" [event]="event">
            </dq-timeline-voice>
            <dq-timeline-text *ngSwitchCase="'Text'" [event]="event">
            </dq-timeline-text>
            <dq-timeline-email *ngSwitchCase="'Email'" [event]="event" [displayEmailReply]="displayEmailReply"
              (reply)="replyEmail($event)" (replyAll)="replyEmailAll($event)" (forward)="forwardEmail($event)">
            </dq-timeline-email>
            <dq-timeline-webform *ngSwitchCase="'WebForm'" [event]="event">
            </dq-timeline-webform>
            <dq-timeline-unknown *ngSwitchCase="'Unknown'" [event]="event">
            </dq-timeline-unknown>
          </ng-container>
        </article>
      </section>
    </div>
  </ng-container>
  <ng-template #mobile>
    <div class="mobile-container">
      <section>
        <article *ngFor="let event of events">
          <ng-container [ngSwitch]="event.type">
            <dq-timeline-appointment *ngSwitchCase="'Appointment'" [event]="event">
            </dq-timeline-appointment>
            <dq-timeline-note *ngSwitchCase="'Note'" [event]="event">
            </dq-timeline-note>
            <dq-timeline-voice *ngSwitchCase="'Voice'" [event]="event">
            </dq-timeline-voice>
            <dq-timeline-text *ngSwitchCase="'Text'" [event]="event">
            </dq-timeline-text>
            <dq-timeline-email *ngSwitchCase="'Email'" [event]="event">
            </dq-timeline-email>
            <dq-timeline-webform *ngSwitchCase="'WebForm'" [event]="event">
            </dq-timeline-webform>
            <dq-timeline-unknown *ngSwitchCase="'Unknown'" [event]="event">
            </dq-timeline-unknown>
          </ng-container>
        </article>
      </section>
    </div>
  </ng-template>
</div>
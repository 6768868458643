import { DecimalPipe, formatDate } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {  MatCheckboxChange } from '@angular/material/checkbox';
import { Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { REPRESENTATIVE_CALENDAR_TYPES, APPOINTMENT_LEAD_TYPE_OPTIONS, TIME_LINE_OPTIONS, APPOINTMENT_SOURCE_OPTIONS, APPOINTMENT_METHOD_OPTIONS, LEAD_SCORE_OPTIONS, PERSONA_OPTIONS, LANGUAGE_OPTIONS } from 'app/features/booking/booking.model';
import { TimeZone } from 'app/shared/models/timezone.model';
import { DigiDecimalPipe } from 'app/shared/pipes/digi-decimal-pipe.pipe';
import { environment } from 'environments/environment';

import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { actionClientDialogBookingEventCalendarAvaliablitiesRequested, actionClientDialogBookingEventCalendarBookingFormBuildingAddressChange, actionClientDialogBookingEventCalendarChangeTimeZone, actionClientDialogBookingEventCalendarCreateAppointment, actionClientDialogBookingEventCalendarDateChange, actionClientDialogBookingEventCalendarLeadSourcesRequested, actionClientDialogBookingEventCalendarReset, actionClientDialogBookingEventCalendarTimeChange, actionClientDialogBookingEventCalendarTimeIdToConfirmChange, actionClientDialogBookingEventCalendarToggleIsFormActive, actionClientDialogBookingEventCalendarToggleScheduleOverwrite, actionClientDialogBookingEventCalendarUpdateAppointment, actionClientDialogBookingEventOverwriteCalendarAvaliablitiesLoaded, actionClientDialogBookingEventOverwriteCalendarAvaliablitiesRequested } from '../client-dialog-booking-calendar.action';
import { selectClientDialogBookingEventCalendarStateIsloaded, selectClientDialogBookingEventCalendarStateRepresentative, selectClientDialogBookingEventCalendarStateEvent, selectClientDialogBookingEventCalendarStateBuildingId, selectClientDialogBookingEventCalendarStateBuildingProperties, selectClientDialogBookingEventCalendarStateTimeSlots, selectClientDialogBookingEventCalendarStateDate, selectClientDialogBookingEventCalendarStateTimeSlot, selectClientDialogBookingEventCalendarStateTimeIdToConfirm, selectClientDialogBookingEventCalendarStateCalendarType, selectClientDialogBookingEventCalendarStateTimeSlotsLoaded, selectClientDialogBookingEventCalendarStateIsSubmiting, selectClientDialogBookingEventCalendarStateTimeZoneOptions, selectClientDialogBookingEventCalendarStateTimeZone, selectClientDialogBookingEventCalendarLeadSources, selectClientDialogBookingEventCalendarIsFormActive, selectClientDialogBookingEventCalendarStateBuildingAddress, selectClientDialogBookingEventCalendaIfBookSucceed } from '../client-dialog-booking-calendar.selector';

import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { BookingEvent, BookingLeadSource, BookingTimeSlot, BuildingProperty, RepresentativeThumbnail } from '../../../client-dialog-inventory.model';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { ClientAppointment, LeadInfo } from 'app/shared/models/lead-info-models';
import { selectClientLeadInfo } from 'app/features/client-dialog/components/client-dialog-user-general/client-dialog-general.selectors'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientDialogInventoryBookingComponent } from '../../client-dialog-inventory-booking/client-dialog-inventory-booking/client-dialog-inventory-booking.component';
import { selectClientDialogClientAppointmentAppointmentInEditting } from '../../../../client-dialog-appointment/client-dialog-appointment.selector'
import { LEAD_PREFERENCE_ACCESSIBILITY_OPTIONS, LEAD_PREFERENCE_BATH_OPTIONS, LEAD_PREFERENCE_BED_OPTIONS, LEAD_PREFERENCE_PARKING_OPTIONS, LEAD_PREFERENCE_PET_OPTIONS, LeadPreference, LeadPreferenceTypes } from 'app/shared/models/lead-preference.model';
import { selectclientDialogPreferencesStateClientPreferences } from 'app/features/client-dialog/components/client-dialog-lead-preference/client-dialog-lead-preference.selector';
import { selectClientDialogIsScheduleOverwrite } from '../../client-dialog-inventory-booking/client-dialog-booking-building-avaliabilty.selector';
import { MatProgressButtonOptions } from '../../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-booking-calendar',
  templateUrl: './client-dialog-booking-calendar.component.html',
  styleUrls: ['./client-dialog-booking-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class ClientDialogBookingCalendarComponent implements OnInit, OnDestroy {

  constructor(
    public store: Store<State>,
    private formBuilder: UntypedFormBuilder,
    private digiDecimalPipe: DigiDecimalPipe,
    private decimalPipe: DecimalPipe,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ClientDialogInventoryBookingComponent>) { }

  unsubscribe$: Subject<void> = new Subject();
  isFormLoaded$: Subject<void> = new Subject();

  countryCode: string;
  @ViewChild('phoneInput')
  phoneInput: NgxMatIntlTelInputComponent;

  isLoaded$: Observable<boolean>;
  representative$: Observable<RepresentativeThumbnail>;
  event$: Observable<BookingEvent>;
  timeSlots$: Observable<BookingTimeSlot[]>;
  timeSlotsLoaded$: Observable<boolean>;
  timeSlot$: Observable<BookingTimeSlot>;
  timeIdToConfirm$: Observable<string>;
  dateTime$: Observable<string>;
  calendarType$: Observable<string>;
  calendarType: string;
  isSubmitting$: Observable<boolean>;

  timeZone$: Observable<TimeZone>;
  timeZone: TimeZone;
  timeZoneOptions$: Observable<TimeZone[]>;
  isScheduleOverwrite$: Observable<boolean>;
  buildingId$: Observable<number>;
  buildingId: number;
  buildingProperties$: Observable<BuildingProperty[]>
  leadSources$: Observable<BookingLeadSource[]>;
  isFormActive$: Observable<boolean>;

  buildingAddress$: Observable<string>;
  buildingAddress: string;

  isScheduleOverwrite: boolean;

  representativeAvatar = '../../../../../../../../assets/user-avatar.png';
  paBaseUrl = environment.baseUrl.paBaseUrl;

  eventForm: UntypedFormGroup;

  overWriteScheduleForm: UntypedFormGroup;

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Book',
    flat: true,
    spinnerSize: 18,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'primary-contrast-80',
    spinnerCustomClass: 'spinner-primary-contrast-30'
  };

  calendarTypes = REPRESENTATIVE_CALENDAR_TYPES;
  bedOptions = LEAD_PREFERENCE_BED_OPTIONS;
  bathOptions = LEAD_PREFERENCE_BATH_OPTIONS;
  parkingOptions = LEAD_PREFERENCE_PARKING_OPTIONS;
  petOptions = LEAD_PREFERENCE_PET_OPTIONS;
  leadTypeOptions = APPOINTMENT_LEAD_TYPE_OPTIONS;
  timelineOptions = TIME_LINE_OPTIONS;
  appointmentSourceOptions = APPOINTMENT_SOURCE_OPTIONS;
  appointmentMethodOptions = APPOINTMENT_METHOD_OPTIONS;
  leadScoreOptions = LEAD_SCORE_OPTIONS;
  accessibilityOptions = LEAD_PREFERENCE_ACCESSIBILITY_OPTIONS;
  personaOptions = PERSONA_OPTIONS;
  languageOptions = LANGUAGE_OPTIONS

  leadSources: BookingLeadSource[] = [];

  minDate = new Date();
  queryParams$: Observable<Params>;

  leadInfo$: Observable<LeadInfo>;
  ifBookSucceed$: Observable<boolean>;

  appointmentEditting$: Observable<ClientAppointment>;
  appointmentEdittingId: number;
  leadId: number = null;

  isEditMode = false;

  baseEventGroupControls: any = {};
  developerEventGroupControls: any = {};
  brokerageEventGroupControls: any = {};
  mortgageEventGroupControls: any = {};
  leadPreferences$: Observable<LeadPreference[]>;
  leadBedPreferences: LeadPreference[];
  leadBathPreferences: LeadPreference[];
  leadBudgetPreference: LeadPreference;
  leadParkingPreference: LeadPreference;
  leadAccessibilityPreference: LeadPreference;
  leadPetsPreferences: LeadPreference[];
  leadMoveInDatePreference: LeadPreference;

  ngOnInit(): void {
    this.leadInfo$ = this.store.pipe(select(selectClientLeadInfo))
    this.baseEventGroupControls.representativeId = new UntypedFormControl('', [Validators.required]);
    this.baseEventGroupControls.eventId = new UntypedFormControl('', [Validators.required]);
    this.baseEventGroupControls.phoneNumber = new UntypedFormControl('', [Validators.required]);
    this.baseEventGroupControls.date = new UntypedFormControl('', [Validators.required]);
    this.baseEventGroupControls.firstName = new UntypedFormControl('', [Validators.required]);
    this.baseEventGroupControls.lastName = new UntypedFormControl('', [Validators.required]);
    this.baseEventGroupControls.email = new UntypedFormControl('', [Validators.required, Validators.email]);
    this.baseEventGroupControls.budget = new UntypedFormControl('', [Validators.required]);
    this.baseEventGroupControls.leadSource = new UntypedFormControl('', [Validators.required]);
    this.baseEventGroupControls.eventDesc = new UntypedFormControl('', );
    this.baseEventGroupControls.language = new UntypedFormControl('', );

    this.developerEventGroupControls = { ...this.baseEventGroupControls };
    this.developerEventGroupControls.propertyId = new UntypedFormControl('', [Validators.required]);
    this.developerEventGroupControls.moveInDate = new UntypedFormControl('', [Validators.required]);
    this.developerEventGroupControls.buildingAddress = new UntypedFormControl('', [Validators.required]);
    this.developerEventGroupControls.bath = new UntypedFormControl('', [Validators.required]);
    this.developerEventGroupControls.parking = new UntypedFormControl('', [Validators.required]);
    this.developerEventGroupControls.pet = new UntypedFormControl('', [Validators.required]);
    this.developerEventGroupControls.bed = new UntypedFormControl('', [Validators.required]);
    this.developerEventGroupControls.accessibility = new UntypedFormControl('', [Validators.required]);

    this.brokerageEventGroupControls = { ...this.baseEventGroupControls };
    this.brokerageEventGroupControls.persona = new UntypedFormControl('', [Validators.required]);
    this.brokerageEventGroupControls.leadType = new UntypedFormControl('', [Validators.required]);
    this.brokerageEventGroupControls.leadScore = new UntypedFormControl('', [Validators.required]);
    this.brokerageEventGroupControls.timeline = new UntypedFormControl('', [Validators.required]);
    this.brokerageEventGroupControls.appointmentSource = new UntypedFormControl('', [Validators.required]);
    this.brokerageEventGroupControls.appointmentMethod = new UntypedFormControl('', [Validators.required]);
    this.brokerageEventGroupControls.numberOfContacts = new UntypedFormControl('', [Validators.required]);
    this.brokerageEventGroupControls.bed = new UntypedFormControl('', [Validators.required]);
    this.brokerageEventGroupControls.bath = new UntypedFormControl('', [Validators.required]);

    this.mortgageEventGroupControls = { ...this.brokerageEventGroupControls };
    this.mortgageEventGroupControls.isPreApproved = new UntypedFormControl('', [Validators.required]);

    this.calendarType$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectClientDialogBookingEventCalendarStateCalendarType));
    this.calendarType$.pipe(takeUntil(this.unsubscribe$)).subscribe(calendarType => {
      this.calendarType = calendarType
      if (this.calendarType == REPRESENTATIVE_CALENDAR_TYPES.showing ||
        this.calendarType == REPRESENTATIVE_CALENDAR_TYPES.virtualBuyer ||
        this.calendarType == REPRESENTATIVE_CALENDAR_TYPES.callWithRepresentative
      ) {
        this.eventForm = this.formBuilder.group(this.brokerageEventGroupControls);
      }
      else if (this.calendarType == REPRESENTATIVE_CALENDAR_TYPES.mortgageConsultation ||
        this.calendarType == REPRESENTATIVE_CALENDAR_TYPES.mortgageVirtualConsultation) {
        this.eventForm = this.formBuilder.group(this.mortgageEventGroupControls);
      }
      else if (this.calendarType == REPRESENTATIVE_CALENDAR_TYPES.developerShowing ||
        this.calendarType == REPRESENTATIVE_CALENDAR_TYPES.developerVirtualShowing) {
        this.eventForm = this.formBuilder.group(this.developerEventGroupControls);
      }
      this.isFormLoaded$.next();
    });

    this.appointmentEditting$ = this.store.pipe(select(selectClientDialogClientAppointmentAppointmentInEditting))
    this.appointmentEditting$.pipe(takeUntil(this.unsubscribe$)).subscribe(appointment => {
      this.appointmentEdittingId = appointment.id
    })

    this.isFormLoaded$.pipe(takeUntil(this.unsubscribe$)).subscribe(x => {
      this.buildingId$.pipe(takeUntil(this.unsubscribe$)).subscribe(buildingId => {
        if (this.eventForm != undefined) {
          this.buildingId = buildingId
          this.eventForm.get('buildingId')?.setValue(buildingId)
        }
      });
      this.buildingAddress$.pipe(takeUntil(this.unsubscribe$)).subscribe(buildingAddress => {
        if (this.eventForm != undefined) {
          this.buildingAddress = buildingAddress
          this.eventForm.get('buildingAddress')?.setValue(buildingAddress)
        }
      });
      this.representative$.pipe(takeUntil(this.unsubscribe$)).subscribe(representative => {
        if (representative.id > 0) {
          this.eventForm.get('representativeId')?.setValue(representative.id)
        }
      });
      this.event$.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
        if (event.id) {
          this.eventForm.get('eventId')?.setValue(event.id)
        }
      });
      this.timeSlot$.pipe(takeUntil(this.unsubscribe$)).subscribe(timeSlot => {
        if (timeSlot.id) {
          this.eventForm.get('date')?.setValue(timeSlot.label)
        } else {
          combineLatest([this.representative$, this.event$, this.dateTime$, this.timeZone$, this.isScheduleOverwrite$])
            .pipe(takeUntil(this.unsubscribe$), debounceTime(0))
            .subscribe(([representative, event, dateTime, timeZone, isScheduleOverwrite]) => {
              if (representative?.id > 0 && event?.id > 0 && dateTime && timeZone) {
                if (!isScheduleOverwrite) {
                  this.store.dispatch(actionClientDialogBookingEventCalendarAvaliablitiesRequested({ repUserId: representative.id, calendarid: event.id, bookingDate: new Date(dateTime).toISOString(), timeZone: timeZone.id }))
                }
                else {
                  this.store.dispatch(actionClientDialogBookingEventOverwriteCalendarAvaliablitiesRequested());
                  const x = 30; // minutes interval
                  const times: string[] = []; // time array
                  let tt = 0; // start time

                  // loop to increment the time and push results in array
                  const date = new Date(dateTime);
                  const strDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
                  for (let i = 0; tt < 24 * 60; i++) {
                    const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
                    const mm = (tt % 60); // getting minutes of the hour in 0-55 format
                    times[i] = strDate + 'T' + ('0' + (hh)).slice(-2) + ':' + ('0' + mm).slice(-2) + ':' + '00'; // pushing data in array in [00:00 - 12:00 AM/PM format]
                    tt = tt + x;
                  }
                  this.store.dispatch(actionClientDialogBookingEventOverwriteCalendarAvaliablitiesLoaded({ timeSlots: times }));
                }
              }
            });
        }
      });
      this.leadSources$.pipe(takeUntil(this.unsubscribe$)).subscribe(leadsources => {
        this.leadSources = leadsources;
        if (this.eventForm != undefined) {
          const defaultLeadSource = leadsources.filter(x => x.isDefault)[0];
          if (defaultLeadSource != undefined) {
            this.eventForm.get('leadSource')?.setValue(defaultLeadSource.id)
          }
        }
      });
      this.leadInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(leadInfo => {
        this.eventForm.get('firstName')?.setValue(leadInfo.firstName);
        this.eventForm.get('lastName')?.setValue(leadInfo.lastName);
        this.eventForm.get('phoneNumber')?.setValue(leadInfo.phoneNumbers[0]);
        this.eventForm.get('email')?.setValue(leadInfo.emails[0]);
        this.leadId = leadInfo.id;
      })

      this.appointmentEditting$.pipe(takeUntil(this.unsubscribe$)).subscribe(appointment => {
        if (appointment.id > 0) {

          this.isEditMode = true;

          this.eventForm.get('bed')?.setValue(appointment.bedPreference)
          this.eventForm.get('budget')?.setValue(appointment.budget)
          this.eventForm.get('eventDesc')?.setValue(appointment.note)
          this.eventForm.get('moveInDate')?.setValue(appointment.moveInDate)
          this.eventForm.get('language')?.setValue(appointment.language)
        }
      })

      this.leadPreferences$ = this.store.pipe(select(selectclientDialogPreferencesStateClientPreferences));
      this.leadPreferences$.pipe(takeUntil(this.unsubscribe$)).subscribe(preferences => {
        this.leadBedPreferences = preferences.filter(x => x.type == LeadPreferenceTypes.BED);
        this.leadBathPreferences = preferences.filter(x => x.type == LeadPreferenceTypes.BATH);
        this.leadPetsPreferences = preferences.filter(x => x.type == LeadPreferenceTypes.PET);
        this.leadBudgetPreference = preferences.find(x => x.type == LeadPreferenceTypes.BUDGET);
        this.leadMoveInDatePreference = preferences.find(x => x.type == LeadPreferenceTypes.MOVE_IN_DATE);
        this.leadParkingPreference = preferences.find(x => x.type == LeadPreferenceTypes.PARKING);
        this.leadAccessibilityPreference = preferences.find(x => x.type == LeadPreferenceTypes.ACCESSIBILITY);

        if (this.leadBudgetPreference) {
          this.eventForm.get('budget')?.setValue(this.decimalPipe.transform(this.leadBudgetPreference.value, '2.'));
        }
        if (this.leadBedPreferences && this.leadBedPreferences.length > 0) {
          this.eventForm.get('bed')?.setValue(this.leadBedPreferences.map(x => x.value));
        }
        if (this.leadBathPreferences && this.leadBathPreferences.length > 0) {
          this.eventForm.get('bath')?.setValue(this.leadBathPreferences.map(x => x.value));
        }
        if (this.leadPetsPreferences && this.leadPetsPreferences.length > 0) {
          this.eventForm.get('pet')?.setValue(this.leadPetsPreferences.map(x => x.value));
        }
        if (this.leadMoveInDatePreference) {
          this.eventForm.get('moveInDate')?.setValue(new Date(this.leadMoveInDatePreference.value));
        }
        if (this.leadParkingPreference) {
          this.eventForm.get('parking')?.setValue(this.leadParkingPreference.value);
        }
        if (this.leadAccessibilityPreference) {
          this.eventForm.get('accessibility')?.setValue(this.leadAccessibilityPreference.value);
        } else {
          this.eventForm?.get('accessibility')?.setValue('');
        }
        this.cdr.markForCheck();
      });
    });

    this.store.dispatch(actionClientDialogBookingEventCalendarLeadSourcesRequested());



    this.buildingAddress$ = this.store.pipe(select(selectClientDialogBookingEventCalendarStateBuildingAddress));
    this.buildingId$ = this.store.pipe(select(selectClientDialogBookingEventCalendarStateBuildingId));
    this.buildingProperties$ = this.store.pipe(select(selectClientDialogBookingEventCalendarStateBuildingProperties));
    this.leadSources$ = this.store.pipe(select(selectClientDialogBookingEventCalendarLeadSources));

    this.overWriteScheduleForm = this.formBuilder.group({
      date: new UntypedFormControl('', [Validators.required]),
      hourMin: new UntypedFormControl('', [Validators.required])
    })

    this.isLoaded$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectClientDialogBookingEventCalendarStateIsloaded));
    this.isScheduleOverwrite$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectClientDialogIsScheduleOverwrite));
    this.representative$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectClientDialogBookingEventCalendarStateRepresentative));
    this.event$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectClientDialogBookingEventCalendarStateEvent));
    this.timeSlots$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectClientDialogBookingEventCalendarStateTimeSlots));
    this.timeSlotsLoaded$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectClientDialogBookingEventCalendarStateTimeSlotsLoaded));
    this.dateTime$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectClientDialogBookingEventCalendarStateDate));
    this.timeSlot$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectClientDialogBookingEventCalendarStateTimeSlot));
    this.timeIdToConfirm$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectClientDialogBookingEventCalendarStateTimeIdToConfirm));
    this.isSubmitting$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectClientDialogBookingEventCalendarStateIsSubmiting));
    this.timeZoneOptions$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectClientDialogBookingEventCalendarStateTimeZoneOptions));
    this.timeZone$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectClientDialogBookingEventCalendarStateTimeZone));
    this.ifBookSucceed$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectClientDialogBookingEventCalendaIfBookSucceed));

    this.isScheduleOverwrite$.pipe(takeUntil(this.unsubscribe$)).subscribe(isScheduleOverWrite => {
      this.isScheduleOverwrite = isScheduleOverWrite
    })

    this.timeZone$.pipe(takeUntil(this.unsubscribe$)).subscribe(timeZone => {
      this.timeZone = timeZone
    })

    this.isSubmitting$.pipe(takeUntil(this.unsubscribe$)).subscribe(isSubmitting => {
      this.spinnerButtonOptions.active = isSubmitting
      this.cdr.markForCheck()
    })

    this.ifBookSucceed$.pipe(takeUntil(this.unsubscribe$)).subscribe(ifBookSucceed => {
      if (ifBookSucceed) {
        this.dialogRef.close(true)
      }
    })

    this.isFormActive$ = this.store.pipe(select(selectClientDialogBookingEventCalendarIsFormActive));
    this.isFormActive$.pipe(takeUntil(this.unsubscribe$)).subscribe(isformActive => {
      if (this.eventForm != undefined) {
        isformActive ? this.eventForm.enable() : this.eventForm.disable();
      }
    });
  }

  overWriteScheduleChange(event: MatCheckboxChange) {
    this.store.dispatch(actionClientDialogBookingEventCalendarToggleScheduleOverwrite({ isScheduleOverwrite: event.checked }));
  }

  enableEventForm() {
    this.store.dispatch(actionClientDialogBookingEventCalendarToggleIsFormActive({ isFormActive: true }));
  }

  getPhotoThumbnail(photoUri: string) {
    return photoUri != null ? environment.baseUrl.paBaseUrl + photoUri : this.representativeAvatar;
  }

  countryChanged(countrySelected) {
    this.countryCode = countrySelected.iso2;
  }

  dateSelected(date: string) {
    this.store.dispatch(actionClientDialogBookingEventCalendarDateChange({ date }))
  }

  changeTimeIdToConfirm(id: string) {
    this.store.dispatch(actionClientDialogBookingEventCalendarTimeIdToConfirmChange({ id }))
  }

  confirmTime(time: BookingTimeSlot) {
    this.store.dispatch(actionClientDialogBookingEventCalendarTimeChange({ time }))
  }

  changeTimeZone(timeZone: TimeZone) {
    this.store.dispatch(actionClientDialogBookingEventCalendarChangeTimeZone({ timeZone }))
  }

  save() {
    if (this.spinnerButtonOptions.active == false) {
      let requestForm = {
      }
      if (!this.eventForm.valid) {
        this.eventForm.markAsTouched()
        return
      }

      requestForm = {
        representativeId: this.eventForm.get('representativeId')?.value,
        calendarId: this.eventForm.get('eventId')?.value,
        date: this.eventForm.get('date')?.value,
        phoneNumber: this.eventForm.get('phoneNumber')?.value,
        firstName: this.eventForm.get('firstName')?.value,
        lastName: this.eventForm.get('lastName')?.value,
        email: this.eventForm.get('email')?.value,
        eventDesc: this.eventForm.get('eventDesc')?.value,
        bed: this.eventForm.get('bed')?.value,
        bath: this.eventForm.get('bath')?.value,
        pet: this.eventForm.get('pet')?.value,
        parking: this.eventForm.get('parking')?.value,
        accessibility: this.eventForm.get('accessibility')?.value,
        persona: this.eventForm.get('persona')?.value,
        leadType: this.eventForm.get('leadType')?.value,
        leadScore: this.eventForm.get('leadScore')?.value,
        timeline: this.eventForm.get('timeline')?.value,
        appointmentSource: this.eventForm.get('appointmentSource')?.value,
        appointmentMethod: this.eventForm.get('appointmentMethod')?.value,
        numberOfContacts: this.eventForm.get('numberOfContacts')?.value,
        moveInDate: this.eventForm.get('moveInDate')?.value,
        budget: this.digiDecimalPipe.transform(this.eventForm.get('budget')?.value + ''),
        timeZone: this.timeZone.id,
        buildingAddress: this.eventForm.get('buildingAddress')?.value,
        buildingId: this.eventForm.get('buildingId')?.value,
        propertyId: this.eventForm.get('propertyId')?.value,
        leadSourceId: this.eventForm.get('leadSource')?.value,
        isPreApproved: this.eventForm.get('isPreApproved')?.value,
        language: this.eventForm.get('language')?.value,
        appointmentId: this.appointmentEdittingId,
        leadId: this.leadId,
        isScheduleOverwrite: this.isScheduleOverwrite
      }

      this.store.dispatch(this.appointmentEdittingId > 0 ? actionClientDialogBookingEventCalendarUpdateAppointment({ formType: this.calendarType, form: requestForm }) :
        actionClientDialogBookingEventCalendarCreateAppointment({ formType: this.calendarType, form: requestForm }))
    }
  }

  getLeadBedOptionLabel(value: string) {
    return this.bedOptions.find(x => x.value == value)?.label
  }

  getLeadBathOptionLabel(value: string) {
    return this.bathOptions.find(x => x.value == value)?.label
  }

  getLeadParkingOptionLabel(value: string) {
    return this.parkingOptions.find(x => x.value == value)?.label
  }

  getLeadPetOptionLabel(value: string) {
    return this.petOptions.find(x => x.value == value)?.label
  }

  getleadTypeLabel(value: string) {
    return this.leadTypeOptions.find(x => x.value == value)?.label
  }

  getLeadsourceLabel(id: number) {
    return this.leadSources.filter(x => x.id == id)[0]?.shortName
  }

  changeBookingFormBuildingAddress(streetAddress: string, city: string, state: string, postalCode: string) {
    this.store.dispatch(actionClientDialogBookingEventCalendarBookingFormBuildingAddressChange({ buildingAddress: `${streetAddress},${city},${state},${postalCode}` }))
  }

  closeBookingDialog() {
    this.dialogRef.close(false)
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
    this.store.dispatch(actionClientDialogBookingEventCalendarReset())
  }
}

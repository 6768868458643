import { SESSION_USER_ROLES } from 'app/core/auth/auth.models';
import { selectUserRoles } from 'app/core/auth/auth.selectors';
import { AuthService } from 'app/core/auth/auth.service';
import {
  selectCallManagerIsCallPanelActive,
  selectCallManagerIsDeviceRegistered,
  selectCallManagerIsIncomingCallConnected,
  selectCallManagerIsOutgoingCallConnected
} from 'app/core/call-manager/call-manager.selectors';
import { selectScreenManagerIsMobile } from 'app/core/screen-manager/screen-manager.selectors';
import { atLeastOneValidator } from 'app/shared/components/form-validators/at-least-one-validaor';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation
} from '@angular/core';
import { FormArray, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';

import {
  AssignedUser, IUpdateTenantProfileParams, TenantInfo, TenantPhoneNumber, Tag
} from '../../../../../shared/models/tenant-info-models';
import { TenantDialogComponent } from '../../../tenant-dialog/tenant-dialog.component';
import {
  actionTenantDialogTenantRepDeleteRequested, actionTenantDialogRepsCreateUpdateSuccessfulStateReset
} from '../../tenant-dialog-tenant-rep/tenant-dialog-tenant-rep.action';
import {
  TenantDialogUserGeneralRepsComponent
} from '../../tenant-dialog-tenant-rep/components/tenant-dialog-user-general-reps/tenant-dialog-user-general-reps.component';
import {
  TenantDialogUserGeneralTagsComponent
} from '../../tenant-dialog-tenant-tag/components/tenant-dialog-user-general-tags/tenant-dialog-user-general-tags.component';
import {
  actionTenantDialogGeneralStateReset, actionTenantUserGeneralProfileChangeIsBaseInfoEditMode,
  actionTenantUserGeneralProfileUpdateRequested
} from '../tenant-dialog-general.action';
import {
  selectTenantDialogTenantCaLLFromPhoneNumbers,
  selectTenantDialogTenantId, selectTenantDialogTenantInfo, selectTenantDialogTenantInfoIsBaseInfoEditMode,
  selectTenantDialogTenantInfoIsBaseInfoSubmitting,
  selectTenantDialogTenantInfoPhoneNumbers
} from '../tenant-dialog-general.selectors';

import { UserPhoneNumber } from 'app/shared/models/user-communication.model';
import { CallManagerService } from 'app/core/call-manager/call-manager.service';
import { PhoneNavItem } from 'app/shared/components/phone-number-menu/phone-number-menu.model';
import { actionTenantDialogTenantCallFromPhoneNumbersRequest } from 'app/features/tenant-dialog/tenant-dialog.actions';
import {  MatMenuTrigger } from '@angular/material/menu';
import { TenantDialogPhoneNumberBlockConfirmComponent } from '../components/phone-number-block-confirm/phone-number-block-confirm.component';
import { PhoneNumberBlockInfo } from 'app/shared/models/phone-number-block-info.model';
import { State } from 'app/features/tenant-dialog/tenant-dialog.state';

@Component({
  selector: 'dq-tenant-dialog-user-general',
  templateUrl: './tenant-dialog-user-general.component.html',
  styleUrls: ['./tenant-dialog-user-general.component.scss', 'tenant-dialog-user-general.component.mobile.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TenantDialogUserGeneralComponent implements OnInit, OnDestroy {

  constructor(private formBuilder: UntypedFormBuilder,
    private store: Store<State>,
    public dialog: MatDialog,
    public clientDialogRef: MatDialogRef<TenantDialogComponent>,
    private callManagerService: CallManagerService,
    private autheService: AuthService) {
  }
  userGeneralTagsAddEditorDialogRef: MatDialogRef<TenantDialogUserGeneralTagsComponent>
  userGeneralRepsAddEditorDialogRef: MatDialogRef<TenantDialogUserGeneralRepsComponent>

  @ViewChildren('emailInput') emails: QueryList<ElementRef>
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  // we create an object that contains coordinates
  menuTopLeftPosition = { x: '0', y: '0' }

  isCallPanelActive$: Observable<boolean>;
  isDeviceRegistered$: Observable<boolean>;
  isIncomingCallOnGoing$: Observable<boolean>;
  isOutgoingCallOnGoing$: Observable<boolean>;

  callFromPhoneNumbers$: Observable<UserPhoneNumber[]>;
  callFromPhoneNumbers: UserPhoneNumber[];
  phoneNavItems: PhoneNavItem[] = [];

  unsubscribe: Subject<void> = new Subject();
  tenantInfo$: Observable<TenantInfo>;
  tenantInfoPhoneNumbers$: Observable<string[]>;
  tenantPhoneNumberBlockInfo: TenantPhoneNumber[];
  isSessionProfile$: Observable<boolean>;
  isTabLoading$: Observable<boolean>;
  dialogProfile: MatDialogRef<TenantDialogUserGeneralTagsComponent>;
  dialogRep: MatDialogRef<TenantDialogUserGeneralRepsComponent>;
  dialogPhoneNumberBlock: MatDialogRef<TenantDialogPhoneNumberBlockConfirmComponent>;
  tenantId$: Observable<number>;
  tenantId: number;

  copiedElement: string;

  isMobile$: Observable<boolean>;
  isMobile: boolean;

  stage: string;

  isNolyDeveloperRole: boolean;

  isBaseInfoEditMode$: Observable<boolean>;
  isBaseInfoSubmitting$: Observable<boolean>;
  isBaseInfoEditMode: boolean;
  isBaseInfoSubmitting: boolean;
  isRepsLoaded$: Observable<boolean>;
  clientReps$: Observable<AssignedUser[]>;
  tags: Tag[];
  reps: AssignedUser[];
  userRoles: string[];

  tagNameControl = new FormControl();
  repNameControl = new FormControl();

  countryCode: string;
  initialName: string = '';
  selectedItemsList = [];
  checkedID = [];

  tenantInfoForm = this.formBuilder.group(
    {
      id: ['', []],
      firstName: ['', []],
      lastName: ['', []],
      emails: new FormArray([
        new FormControl('', [Validators.email])
      ]),
      phoneNumbers: new FormArray([
        new FormControl('', [])
      ]),
      sourceName: ['', []]
    },
    { validators: atLeastOneValidator(['emails', 'phoneNumbers']) }
  )


  ngOnInit(): void {

    this.store.pipe(select(selectUserRoles), takeUntil(this.unsubscribe)).subscribe(roles => {
      this.userRoles = roles
    })

    this.tenantId$ = this.store.pipe(select(selectTenantDialogTenantId))
    this.tenantId$.pipe(takeUntil(this.unsubscribe)).subscribe(tenantId => {
      if (tenantId > 0) {
        this.tenantId = tenantId
        this.store.dispatch(actionTenantDialogTenantCallFromPhoneNumbersRequest({ tenantId: tenantId }))
      }
    })

    this.isBaseInfoEditMode$ = this.store.pipe(select(selectTenantDialogTenantInfoIsBaseInfoEditMode))
    this.isBaseInfoSubmitting$ = this.store.pipe(select(selectTenantDialogTenantInfoIsBaseInfoSubmitting))
    this.isBaseInfoEditMode$.pipe(takeUntil(this.unsubscribe)).subscribe(isBaseInfoEditMode => {
      this.isBaseInfoEditMode = isBaseInfoEditMode
    })
    this.isBaseInfoSubmitting$.pipe(takeUntil(this.unsubscribe)).subscribe(isBaseInfoSubmitting => {
      this.isBaseInfoSubmitting = isBaseInfoSubmitting
    })
    this.tenantInfo$ = this.store.pipe(select(selectTenantDialogTenantInfo))
    this.tenantInfo$.pipe(takeUntil(this.unsubscribe)).subscribe(tenantInfo => {
      this.tenantPhoneNumberBlockInfo = tenantInfo.phoneNumberBlockInfo
    })
    this.isMobile$ = this.store.pipe(select(selectScreenManagerIsMobile))
    this.isMobile$.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => {
      this.isMobile = isMobile
    })

    this.isIncomingCallOnGoing$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsIncomingCallConnected));
    this.isOutgoingCallOnGoing$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsOutgoingCallConnected));
    this.isCallPanelActive$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsCallPanelActive));
    this.isDeviceRegistered$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectCallManagerIsDeviceRegistered));

    this.tenantInfoPhoneNumbers$ = this.store.pipe(select(selectTenantDialogTenantInfoPhoneNumbers));
    this.callFromPhoneNumbers$ = this.store.pipe(takeUntil(this.unsubscribe), select(selectTenantDialogTenantCaLLFromPhoneNumbers));
    this.callFromPhoneNumbers$.pipe(takeUntil(this.unsubscribe)).subscribe(callFromPhoneNumbers => {
      this.callFromPhoneNumbers = callFromPhoneNumbers;
    })

    combineLatest([this.callFromPhoneNumbers$, this.tenantInfoPhoneNumbers$]).pipe(takeUntil(this.unsubscribe)).subscribe(([callFromPhoneNumbers, tenantInfoPhoneNumbers]) => {
      callFromPhoneNumbers.forEach(fromPhoneNumber => {
        let parentItem = <PhoneNavItem>{
          displayName: fromPhoneNumber.friendlyName,
          value: fromPhoneNumber.phoneNumber,
          iconName: 'call',
          children: []
        }
        tenantInfoPhoneNumbers.forEach(toPhoneNumber => {
          let childItem = <PhoneNavItem>{
            displayName: toPhoneNumber,
            parentValue: fromPhoneNumber.phoneNumber,
            iconName: 'call',
            value: toPhoneNumber
          }
          parentItem.children.push(childItem);
        })
        this.phoneNavItems.push(parentItem);
      });
    });

    combineLatest([this.isBaseInfoEditMode$, this.tenantInfo$]).pipe(takeUntil(this.unsubscribe)).subscribe(([isBaseInfoEditMode, tenantInfo]) => {
      if (tenantInfo) {
        this.tenantInfoForm.enable()
        this.tenantInfoForm.get('id').setValue(tenantInfo.id)
        this.tenantInfoForm.get('firstName').setValue(tenantInfo.firstName)
        this.tenantInfoForm.get('lastName').setValue(tenantInfo.lastName)

        if (isBaseInfoEditMode) {
          if (tenantInfo.emails.length > this.emailArray.length) {
            tenantInfo.emails.forEach((email, index) => {
              if (index > 0 && (this.emailArray.length <= tenantInfo.emails.length)) {
                this.addEmail()
              }
            })
          }
          let emailFormArray = this.tenantInfoForm.get('emails') as FormArray
          tenantInfo.emails.forEach((email, index) => {
            emailFormArray.at(index).patchValue(email)
          })
          if (tenantInfo.phoneNumbers.length > this.phoneArray.length) {
            tenantInfo.phoneNumbers.forEach((phoneNumber, index) => {
              if (index > 0 && (this.phoneArray.length <= tenantInfo.phoneNumbers.length)) {
                this.addPhone()
              }
            })
          }
          let phoneNumberFormArray = this.tenantInfoForm.get('phoneNumbers') as FormArray
          tenantInfo.phoneNumbers.forEach((phoneNumber, index) => {
            phoneNumberFormArray.at(index).patchValue(phoneNumber)
          })
        }


        // this.tenantInfoForm.get('sourceName').setValue(tenantInfo.tenantSource.name)
        let initial = tenantInfo.firstName?.charAt(0)?.toUpperCase() + tenantInfo.lastName?.charAt(0)?.toUpperCase()
        this.initialName = initial
      }

    })
  }

  onPhoneRightClick(event: MouseEvent, phoneNumber: string) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item: phoneNumber }

    // we open the menu
    this.matMenuTrigger.openMenu();
  }

  toggleClientInfoEditMode(isEditMode) {
    this.store.dispatch(actionTenantUserGeneralProfileChangeIsBaseInfoEditMode({ isEditMode }))
  }

  saveChanges() {
    if (this.isBaseInfoSubmitting) {
      return
    }
    this.tenantInfoForm.markAllAsTouched()
    if (this.tenantInfoForm.invalid) {
      return
    }
    let params: IUpdateTenantProfileParams =
    {
      id: this.tenantInfoForm.get('id').value,
      firstName: this.tenantInfoForm.get('firstName').value,
      lastName: this.tenantInfoForm.get('lastName').value,
      emails: this.tenantInfoForm.get('emails').value,
      phoneNumbers: this.tenantInfoForm.get('phoneNumbers').value,
    }
    this.store.dispatch(actionTenantUserGeneralProfileUpdateRequested({ params }))
  }

  ifPhoneNumberBlocked(phoneNumber: string) {
    return this.tenantPhoneNumberBlockInfo.find(x => x.phoneNumber == phoneNumber)?.isPhoneNumberBlocked;
  }

  getPhoneNumberBlockInfo(phoneNumber: string) {
    return this.tenantPhoneNumberBlockInfo.find(x => x.phoneNumber == phoneNumber);
  }

  countryChanged(countrySelected: string | any) {
    this.countryCode = countrySelected.iso2
  }


  deleteRep(repId: number) {
    this.store.dispatch(actionTenantDialogTenantRepDeleteRequested({ tenantId: this.tenantId, repId: repId }))
  }


  openPhoneNumberDialog(phoneNumber: string): void {
    let data: PhoneNumberBlockInfo = {
      updateType: this.ifPhoneNumberBlocked(phoneNumber) ? 'Unblock' : 'Block',
      phoneNumber: phoneNumber,
      phoneNumberId: this.getPhoneNumberBlockInfo(phoneNumber)?.id
    }
    this.dialogPhoneNumberBlock = this.dialog.open(TenantDialogPhoneNumberBlockConfirmComponent, {
      width: this.isMobile ? '100vw' : '575px',
      height: this.isMobile ? '100dvh' : '',
      autoFocus: false,
      panelClass: 'no-padding-dialog-container',
      disableClose: false,
      data: data
    })

    this.dialogPhoneNumberBlock.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
    })
  }

  openRepAddEditDialogEdit(rep?: AssignedUser): void {
    this.dialogRep = this.dialog.open(TenantDialogUserGeneralRepsComponent, {
      width: this.isMobile ? '100vw' : '575px',
      maxWidth: '100vw !important',
      height: this.isMobile ? '100dvh' : '',
      autoFocus: false,
      panelClass: 'no-padding-dialog-container',
      disableClose: false,
    })

    this.dialogRep.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.store.dispatch(actionTenantDialogRepsCreateUpdateSuccessfulStateReset())
    })
  }

  get emailArray() {
    return this.tenantInfoForm.get('emails') as FormArray
  }

  public removeEmail(index) {
    this.emailArray.removeAt(index)
  }

  public addEmail() {
    let newFormControl: FormControl = new FormControl('', [
      Validators.required,
      Validators.email
    ])
    this.emailArray.push(newFormControl)
    setTimeout(() => {
      this.emails.last.nativeElement.focus()
    })
  }


  get phoneArray() {
    return this.tenantInfoForm.get('phoneNumbers') as FormArray
  }

  public removePhone(index) {
    this.phoneArray.removeAt(index)
  }


  public addPhone() {
    let newFormControl: FormControl = new FormControl('', [
      Validators.required
    ])
    this.phoneArray.push(newFormControl)
  }

  closeDialog() {
    this.clientDialogRef.close()
  }

  hasAccessToBooking(): Observable<boolean> {
    return this.autheService.isUserInRole([SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesBookingManager])
  }



  makeOutgoingCall(fromPhoneNumber: string, toPhoneNumber: string) {
    this.callManagerService.startOutgoingCall(fromPhoneNumber, toPhoneNumber);
  }

  makeOutgoingCallMultiSelect(event$: any) {
    this.callManagerService.startOutgoingCall(event$.from, event$.to);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
    this.store.dispatch(actionTenantDialogGeneralStateReset())
  }

}

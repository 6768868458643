import { AuthState } from './auth.models';
import { authLogin, authLogout, authLoginSuccess, authRefreshTokenSuccess, authTwoFactorAuthVerify, authTwoFactorAuthVerifySuccess, authTwoFactorAuthVerifyFailed, authTwoFactorAuth, authTwoFactorAuthVerifyReSendCode, authTwoFactorAuthVerifyReSendCodeSuccess, authTwoFactorAuthVerifyReSendCodeFailed } from './auth.actions';
import { createReducer, on, Action } from '@ngrx/store';

export const initialState: AuthState = {
  isAuthenticated: false
};

const reducer = createReducer(
  initialState,
  on(authLogin, state => ({ isAuthenticated: true })),
  on(authLoginSuccess,
    authRefreshTokenSuccess,
    (state, { token }) => ({
      isAuthenticated: true,
      token: token.token,
      refreshToken: token.refreshToken,
      expires: token.expires,
      roles: token.roles,
      profile: {
        userName: token.userName,
        firstName: token.firstName,
        lastName: token.lastName,
        userId: token.userId,
        userTimeZone: token.userTimeZone,
        frontVerificationHash: token.frontVerificationHash
      }
    })),
  on(authTwoFactorAuth,
    (state, { token }) => ({
      isAuthenticated: false,
      token: token.token,
      refreshToken: token.refreshToken,
      expires: token.expires,
      profile: {
        userName: token.userName,
        firstName: token.firstName,
        lastName: token.lastName,
        userId: token.userId,
        userTimeZone: token.userTimeZone,
        frontVerificationHash: token.frontVerificationHash
      },
      isRequiredTwoFactor: true
    })),
  on(authTwoFactorAuthVerify, state => ({
    ...state,
    twoFactorAuthVetifying: true
  })),
  on(authTwoFactorAuthVerifySuccess, (state, { token }) => ({
    ...state,
    twoFactorAuthVetifying: false,
    isAuthenticated: true,
    isRequiredTwoFactor: false,
    roles: token.roles,
  })),
  on(authTwoFactorAuthVerifyFailed, state => ({
    ...state,
    twoFactorAuthVetifying: false,
    isAuthenticated: false
  })),
  on(authTwoFactorAuthVerifyReSendCode, state => ({
    ...state,
    isResendingCode: true
  })),
  on(
    authTwoFactorAuthVerifyReSendCodeSuccess,
    authTwoFactorAuthVerifyReSendCodeFailed,
    state => ({
      ...state,
      isResendingCode: false
    })),
  on(authLogout, state => initialState)
);

export function authReducer(
  state: AuthState | undefined,
  action: Action
): AuthState {
  return reducer(state, action);
}

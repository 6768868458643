import { SESSION_USER_ROLES } from "app/core/auth/auth.models";

export const NAVIGATION: NavItem[] = [
  {
    id: 'appointment-management',
    link: 'no-link',
    label: 'dq.menu.appointment-management',
    icon: 'date_range',
    iconType: 'material',
    isLeaf: false,
    children: [{
      id: 'dashboard',
      link: 'dashboard',
      label: 'dq.menu.dashboard',
      icon: 'dashboard',
      iconType: 'material',
      isLeaf: true,
      children: [],
      roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesAppointmentManager, SESSION_USER_ROLES.SalesAppointmentUser],
      isForMobile: false
    },
    {
      id: 'lead-appointment',
      link: 'lead-appointment',
      label: 'dq.menu.lead-appointment',
      icon: 'calendar-app',
      iconType: 'custom',
      isLeaf: true,
      children: [],
      roles: [SESSION_USER_ROLES.Admin],
      isForMobile: false
    }],
    roles: [SESSION_USER_ROLES.Admin],
    isForMobile: false,
  },
  {
    id: 'booking',
    link: 'booking',
    label: 'dq.menu.booking',
    icon: 'calendar_today',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesBookingManager],
    isForMobile: false
  },
  {
    id: 'representative-scheduler',
    link: 'representative-scheduler',
    label: 'dq.menu.rep-scheduler',
    icon: 'schedule',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.Representative, SESSION_USER_ROLES.SalesScheduleManager],
    isForMobile: false
  },
  {
    id: 'user-schedule',
    link: 'user-schedule',
    label: 'dq.menu.user-schedule',
    icon: 'perm_contact_calendar',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesScheduleManager],
    isForMobile: false
  },
  {
    id: 'lead-manager',
    link: 'lead-manager',
    label: 'dq.menu.lead-manager',
    icon: 'track_changes',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesLeadManager, SESSION_USER_ROLES.SalesLeadUser],
    isForMobile: false
  },
  {
    id: 'approval-queue',
    link: 'approval-queue',
    label: 'dq.menu.approval-queue',
    icon: 'checklist',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.ApplicationApprovalsManager, SESSION_USER_ROLES.LeaseSignatory, SESSION_USER_ROLES.ApplicationCreator],
    isForMobile: false
  },
  {
    id: 'tenant-manager',
    link: 'tenant-manager',
    label: 'dq.menu.tenant-manager',
    icon: 'group',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin],
    isForMobile: false
  },
  {
    id: 'service-request-manager',
    link: 'service-request-manager',
    label: 'dq.menu.service-request-manager',
    icon: 'room_service',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.ServiceReceptionist],
    isForMobile: false
  },
  {
    id: 'com-temaplte',
    link: 'com-temaplte',
    label: 'dq.menu.template',
    icon: 'insert_comment',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.AutoComTemplateManager],
    isForMobile: false
  },
  {
    id: 'automated-delayed-task',
    link: 'automated-delayed-task',
    label: 'dq.menu.automated.task',
    icon: 'assignment_turned_in',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin],
    isForMobile: false
  },
  {
    id: 'unified-inbox',
    link: 'unified-inbox',
    label: 'dq.menu.unified-inbox',
    icon: 'mail',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesUnifiedInboxManager],
    isForMobile: false
  },
  {
    id: 'tenant-unified-inbox',
    link: 'tenant-unified-inbox',
    label: 'dq.menu.tenant-unified-inbox',
    icon: 'chat_bubble',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin],
    isForMobile: false
  },
  {
    id: 'building',
    link: 'building',
    label: 'dq.menu.building',
    icon: 'location_city',
    iconType: 'material',
    isLeaf: false,
    children: [
      {
        id: 'building',
        link: 'building',
        label: 'dq.menu.building',
        icon: 'location_city',
        iconType: 'material',
        isLeaf: true,
        children: [],
        roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.BuildingManager],
        isForMobile: false
      },
      {
        id: 'amenity',
        link: 'amenity',
        label: 'dq.menu.amenities',
        icon: 'all_inclusive',
        iconType: 'material',
        isLeaf: true,
        children: [],
        roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.BuildingManager],
        isForMobile: false
      },
    ],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.BuildingManager],
    isForMobile: false
  },
  {
    id: 'user-account',
    link: 'user-account',
    label: 'dq.menu.user-account',
    icon: 'person_add',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.UserManager],
    isForMobile: false
  },
  {
    id: 'inventory',
    link: 'inventory',
    label: 'dq.menu.inventory',
    icon: 'business',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesInventoryUser],
    isForMobile: false
  },
  {
    id: 'rental-form-templates',
    link: 'rental-form-templates',
    label: 'dq.menu.rental-form-templates',
    icon: 'flip_to_front',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin],
    isForMobile: false
  },
  {
    id: 'tasks',
    link: 'tasks',
    label: 'dq.menu.tasks',
    icon: 'list',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.TaskManager, SESSION_USER_ROLES.TaskUser],
    isForMobile: false
  },
  {
    id: 'account-settings',
    link: 'account-settings',
    label: 'dq.menu.account-settings',
    icon: 'settings_applications',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.User],
    isForMobile: false
  },
  {
    id: 'user-profile',
    link: 'user-profile',
    label: 'dq.menu.user-profile',
    icon: 'account_circle',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.User],
    isForMobile: false
  },
  {
    id: 'appointments',
    link: 'dashboard/appointments',
    label: 'dq.menu.appointments',
    icon: 'calendar_today',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesAppointmentManager, SESSION_USER_ROLES.SalesAppointmentUser],
    isForMobile: true,
    isFooterMenu: true
  },
  {
    id: 'mobile-schedule',
    link: 'dashboard/mobile-schedule',
    label: 'dq.menu.scheduler',
    icon: 'access_time',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesScheduleManager],
    isForMobile: true,
    isFooterMenu: true
  },
  {
    id: 'leads',
    link: 'lead-manager/leads',
    label: 'dq.menu.dp-leads-manager',
    icon: 'track_changes',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesLeadManager, SESSION_USER_ROLES.SalesLeadUser],
    isForMobile: true,
    isFooterMenu: true
  },
  {
    id: 'inventory-mobile',
    link: 'dashboard/inventory',
    label: 'dq.menu.inventory',
    icon: 'domain',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesInventoryUser],
    isForMobile: true,
    isFooterMenu: true
  },
  {
    id: 'security',
    link: 'user-profile/security',
    label: 'dq.menu.profile',
    icon: 'account_circle',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.User],
    isForMobile: true,
    isFooterMenu: false
  },
  {
    id: 'dashboard-user',
    link: 'dashbaord-user',
    label: 'dq.menu.dashboard-user',
    icon: 'timeline',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.UserLiveManager],
    isForMobile: false
  },
  {
    id: 'merchant-account',
    link: 'merchant-account',
    label: 'dq.menu.merchant-account',
    icon: 'work',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.MerchantAccountManager],
    isForMobile: false
  },
  {
    id: 'ai-simulation',
    link: 'ai-simulation',
    label: 'dq.menu.ai-simulation',
    icon: 'format_shapes',
    iconType: 'material',
    isLeaf: true,
    children: [],
    roles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.MerchantAccountManager],
    isForMobile: false
  }
];

export interface NavItem {
  id: string;
  link: string;
  label: string;
  icon: string;
  iconType: string;
  isLeaf: boolean;
  children: NavItem[];
  roles: string[];
  isForMobile: boolean;
  isFooterMenu?: boolean;
}

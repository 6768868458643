<div class="tenant-dialog-activities-component">
    <ng-container *ngIf="!(isMobile$ | async); else mobile">
        <div class="desktop-container">
            <mat-tab-group dynamicHeight>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">list</mat-icon>
                        All
                    </ng-template>
                    <div class="tab-content">
                        <ng-container *ngIf="isActivitiesLoaded$ | async">
                            <dq-timeline [events]="allActivities" [displayEmailReply]="true"
                                (reply)="replyEmail($event)" (replyAll)="replyEmailAll($event)" (forward)="forwardEmail($event)"></dq-timeline>
                        </ng-container>
                        <ng-container *ngIf="!(isActivitiesLoaded$ | async)">
                            <mat-spinner class="activity-loading-spinner"></mat-spinner>
                        </ng-container>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">email</mat-icon>
                    </ng-template>
                    <div class="tab-content">
                        <ng-container *ngIf="isActivitiesLoaded$ | async">
                            <dq-timeline [events]="emailActivities" [displayEmailReply]="true"
                                (reply)="replyEmail($event)" (replyAll)="replyEmailAll($event)" (forward)="forwardEmail($event)"></dq-timeline>
                        </ng-container>
                        <ng-container *ngIf="!(isActivitiesLoaded$ | async)">
                            <mat-spinner class="activity-loading-spinner"></mat-spinner>
                        </ng-container>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">sms</mat-icon>
                    </ng-template>
                    <div class="tab-content">
                        <ng-container *ngIf="isActivitiesLoaded$ | async">
                            <dq-timeline [events]="smsActivities"></dq-timeline>
                        </ng-container>
                        <ng-container *ngIf="!(isActivitiesLoaded$ | async)">
                            <mat-spinner class="activity-loading-spinner"></mat-spinner>
                        </ng-container>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">phone</mat-icon>
                    </ng-template>
                    <div class="tab-content">
                        <ng-container *ngIf="isActivitiesLoaded$ | async">
                            <dq-timeline [events]="callActivities"></dq-timeline>
                        </ng-container>
                        <ng-container *ngIf="!(isActivitiesLoaded$ | async)">
                            <mat-spinner class="activity-loading-spinner"></mat-spinner>
                        </ng-container>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">note_alt</mat-icon>
                    </ng-template>
                    <div class="tab-content">
                        <ng-container *ngIf="isActivitiesLoaded$ | async">
                            <dq-timeline [events]="noteActivities"></dq-timeline>
                        </ng-container>
                        <ng-container *ngIf="!(isActivitiesLoaded$ | async)">
                            <mat-spinner class="activity-loading-spinner"></mat-spinner>
                        </ng-container>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">date_range</mat-icon>
                    </ng-template>
                    <div class="tab-content">
                        <ng-container *ngIf="isActivitiesLoaded$ | async">
                            <dq-timeline [events]="appointmentActivities"></dq-timeline>
                        </ng-container>
                        <ng-container *ngIf="!(isActivitiesLoaded$ | async)">
                            <mat-spinner class="activity-loading-spinner"></mat-spinner>
                        </ng-container>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">dvr</mat-icon>
                    </ng-template>
                    <div class="tab-content">
                        <ng-container *ngIf="isActivitiesLoaded$ | async">
                            <dq-timeline [events]="webActivities"></dq-timeline>
                        </ng-container>
                        <ng-container *ngIf="!(isActivitiesLoaded$ | async)">
                            <mat-spinner class="activity-loading-spinner"></mat-spinner>
                        </ng-container>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <ng-container *ngIf="communicationCount < (communicationTotalecords$ | async)">
                <div class="load-more-button-div">
                    <dq-spinner-button class="load-more-button" type="button" (click)="loadMoreCommunications()"
                        [options]="spinnerButtonOptions"></dq-spinner-button>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-template #mobile>
        <div class="mobile-container">
            <ng-container *ngIf="isActivitiesLoaded$ | async">
                <dq-timeline [events]="allActivities"></dq-timeline>
            </ng-container>
            <ng-container *ngIf="!(isActivitiesLoaded$ | async)">
                <mat-spinner class="activity-loading-spinner"></mat-spinner>
            </ng-container>
            <ng-container *ngIf="communicationCount < (communicationTotalecords$ | async)">
                <dq-spinner-button class="load-more-button" type="button" (click)="loadMoreCommunications()"
                    [options]="spinnerButtonOptions"></dq-spinner-button>
            </ng-container>
        </div>
    </ng-template>
</div>

<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="app-component">
  <div [class]="'theme-wrapper ' + (theme$ | async)" [lang]="language$ | async">
    <mat-sidenav-container>
      <mat-sidenav #sidenav mode="side">
        <div class="branding">
          <!-- <span>{{ 'dq.title.short' | translate}}</span> -->
          <img alt="DashQ Logo" [src]="dashQFullLogo">
        </div>
        <!-- LE MENU EST ICI -->
        <mat-nav-list>
          <a mat-list-item *ngFor="let item of navigationSideMenu" (click)="sidenav.close()" [routerLink]="[item.link]"
             routerLinkActive="active">
            {{ item.label | translate }}
          </a>
        </mat-nav-list>
      </mat-sidenav>

      <div class="wrapper">
        <div class="toolbar" [style.display]="(isAuthenticated$ | async) ? 'block' : 'none'"
             [style.position]="(stickyHeader$ | async) ? 'fixed' : 'inherit'"
             [class.mat-elevation-z4]="(stickyHeader$ | async)">
          <mat-toolbar class="main-toolbar primary-toolbar">

            <button mat-icon-button (click)="isToggledSidenav = !isToggledSidenav" *ngIf="!!(isAuthenticated$ | async)">
              <mat-icon>menu</mat-icon>
            </button>

            <span class="branding spacer center d-inline d-sm-none"></span>
            <span routerLink="" class="branding spacer center d-none d-sm-inline d-md-none"> {{
                'dq.title.short' |
                  translate
              }}</span>
            <!-- <span routerLink="" class="branding spacer d-none d-md-inline"> {{ 'dq.title.long' | translate}}</span> -->
            <!-- <span routerLink="" class="branding spacer d-none d-md-inline"> DashQ</span> -->
            <span class="spacer d-none d-md-inline"> <a routerLink=""><img style="width: 120px; cursor: pointer;"
                                                                           [src]="dashQFullLogo"></a></span>
            <ng-container *ngIf="(isMobile$ | async)">
              <span class="spacer"><img style="width: 80px" [src]="dashQFullLogo"></span>
            </ng-container>


            <mat-menu #languagesMenu="matMenu">
              <button mat-menu-item (click)="onLanguageClick($event)" *ngFor="let l of languages" [value]="l">
                {{ l.toUpperCase() }}
                <mat-icon *ngIf="(language$ | async) === l">check</mat-icon>
              </button>
            </mat-menu>


            <div class="index-search-div">
              <dq-index-search></dq-index-search>
            </div>
            <span class="spacer"></span>
            <ng-container *ngIf="(isAuthenticated$ | async)">
              <ng-container *ngIf="connectionState$ | async as connectionState">
                <div *ngIf="connectionState !== 'Connected'" class="connection-container"
                     [ngClass]="{'connection-not-active': (connectionState!=='Connected')}">
                  <div class="connection-status">
                    {{ connectionState }}
                  </div>
                </div>
              </ng-container>

              <!--
                <button mat-icon-button (click)="onThemeClick()" [ngClass]="{ 'theme-icon-off': (!isLightTheme()) }">
                  <mat-icon>wb_sunny</mat-icon>
                </button>
              -->

              <button mat-icon-button>
                <mat-icon class="notification-icon"
                          [ngClass]="{'notification-icon-with-badge': (notificationCount$ | async) > 0}"
                          [matBadge]="(notificationCount$ | async) > 0? (notificationCount$ | async) : ''"
                         
                          matBadgeSize="small" [matMenuTriggerFor]="notificationMenu">notifications
                </mat-icon>
              </button>
              <mat-menu class="app-notification-overlay" #notificationMenu="matMenu" xPosition="before">
                <dq-notification></dq-notification>
              </mat-menu>
              <ng-container *ngIf="(tokenForCall$ | async) && (isCallDeviceRegistered$ | async); else notRegistered">
                <button mat-icon-button (click)="toggleCallPanelOpen()">
                  <mat-icon class="call-icon call-icon-on">perm_phone_msg</mat-icon>
                </button>
              </ng-container>
              <ng-template #notRegistered>
                <button mat-icon-button (click)="toggleCallPanelOpen()">
                  <mat-icon class="call-icon call-icon-off">perm_phone_msg</mat-icon>
                </button>
              </ng-template>
            </ng-container>
            <ng-container>
              <button id="account" *ngIf="(isAuthenticated$ | async)" mat-icon-button
                      [matMenuTriggerFor]="toolbarUserMenu">
                <img class="profile-pic" *ngIf="(profile$ |async)?.profilePic as pic; else userCircle" [src]="pic"
                     alt="Profile Image">
                <ng-template #userCircle>
                  <fa-icon icon="user-circle"></fa-icon>
                </ng-template>

              </button>
            </ng-container>
            <mat-menu #toolbarUserMenu="matMenu">
              <!--
                    <button mat-menu-item (click)="onProfileClick()">
                      <mat-icon>
                        <fa-icon icon="user-circle"></fa-icon>
                      </mat-icon>
                      <span>{{ 'dq.menu.user-profile' | translate }}</span>
                    </button>
                  -->
              <button mat-menu-item (click)="onLogoutClick()">
                <mat-icon>
                  <fa-icon icon="power-off"></fa-icon>
                </mat-icon>
                <span>{{ 'dq.menu.logout' | translate }}</span>
              </button>
            </mat-menu>
          </mat-toolbar>
        </div>
        <ng-container *ngIf="!(isAuthenticated$ | async);else authenticated">
          <div class="application-content">
            <router-outlet #o="outlet"></router-outlet>
          </div>
        </ng-container>

        <ng-template #authenticated>
          <mat-sidenav-container class="main-sidenav-container global-background-color" #innerSidenavContainer
                                 [class]="{'small': isSideNavSmall()}" [autosize]="autosize">
            <mat-sidenav class="app-mian-sidenav" #innerSidenav mode="side" opened [class]="{'small': isSideNavSmall()}"
                         (mouseenter)="startDelay()" (mouseleave)="cancelDelay()">


              <mat-nav-list class="inner-side-nav-menu-list">
                <ng-container *ngFor="let item of navigationSideMenu">
                  <ng-container *ngIf="!item.isLeaf && !isSideNavSmall(); else smallContainer">
                    <mat-expansion-panel class='sidenav-expansion-panel'
                                         [class]="{'small': isSideNavSmall()}">
                      <mat-expansion-panel-header [class]="{'small': isSideNavSmall()}">
                        <mat-panel-title [class]="{'small': isSideNavSmall()}" class="d-flex ml-2">
                          <ng-container *ngIf="item.iconType === 'material'; else svgIcon">
                            <mat-icon>{{ item.icon }}</mat-icon>
                          </ng-container>
                          <ng-template #svgIcon>
                            <mat-icon class="svg-icon" svgIcon="{{item.icon}}"></mat-icon>
                          </ng-template>
                          <span class="list-item-label d-none" [class]="{'d-flex': !isSideNavSmall()}">
                            {{ item.label | translate }}
                          </span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <a mat-list-item *ngFor="let child of item.children" [routerLink]="[child.link]"
                         routerLinkActive="active" [matTooltip]="item.label|translate" #tooltip="matTooltip"
                         [matTooltipDisabled]="!isSideNavSmall()" matTooltipPosition="right"
                         matTooltipClass="link-tip link-tip-left">
                      <span class="d-flex align-center">
                        @if (child.iconType === 'material') {
                          <mat-icon *ngIf="child.iconType === 'material'; else svgIcon">{{ child.icon }}</mat-icon>
                        } @else {
                          <mat-icon class="svg-icon" svgIcon="{{child.icon}}"></mat-icon>
                        }
                        <span class="list-item-label d-none" [class]="{'d-flex': !isSideNavSmall()}">
                          {{ child.label | translate }}
                        </span>
                      </span>
                      </a>
                    </mat-expansion-panel>
                  </ng-container>

                  <ng-template #smallContainer>

                    <a mat-list-item [routerLink]="item.isLeaf ? [item.link] : [checkIfActive(item.children)]"
                       routerLinkActive="active" [class]="{'small': isSideNavSmall()}"
                       [matTooltip]="item.label|translate" #tooltip="matTooltip"
                       [matTooltipDisabled]="!isSideNavSmall()" matTooltipPosition="right"

                       matTooltipClass="link-tip link-tip-left" class="sidenav-link"
                       (click)="toggleInnerSidenav()">
                      <span class="d-flex align-center">

                        @if (item.iconType === 'material') {
                          <mat-icon>{{ item.icon }}</mat-icon>
                        } @else {
                          <mat-icon class="svg-icon" svgIcon="{{item.icon}}"></mat-icon>
                        }
                        <ng-container *ngIf="taskNotificationCount$ | async as taskCount">
                            <span *ngIf="item.id === 'tasks'" class="menu-item-badge" matBadge="{{taskCount}}"
                                  matBadgePosition="after"matBadgeSize="small"
                                  [matBadgeHidden]="false">
                            </span>
                      </ng-container>
                      <ng-container *ngIf="userLeadConversationNotificationCount$ | async as conversationCount">
                            <span *ngIf="item.id === 'unified-inbox'" class="menu-item-badge"
                                  matBadge="{{conversationCount}}" matBadgePosition="after"
                                  matBadgeSize="small" [matBadgeHidden]="false"> </span>
                      </ng-container>
                      <ng-container
                        *ngIf="userTenantConversationNotificationCount$ | async as tenantConversationCount">
                            <span *ngIf="item.id === 'tenant-unified-inbox'" class="menu-item-badge"
                                  matBadge="{{tenantConversationCount}}" matBadgePosition="after"
                                  matBadgeSize="small" [matBadgeHidden]="false"> </span>
                      </ng-container>

                      <span class="list-item-label d-none" [class]="{'d-flex': !isSideNavSmall()}">
                          {{ item.label | translate }}
                        </span>
                      </span>
                    </a>
                  </ng-template>
                </ng-container>
              </mat-nav-list>


              <div class="sidenav-footer flex-wrap d-flex justify-content-center p-2">
                <a id="site" routerLink="">
                  <img class="digilogo" style="width: 100%; max-width: 60px;" [src]="dashQLogo"
                       alt="DigiRealty Technologies">
                </a>
              </div>
            </mat-sidenav>
            <mat-tab-nav-panel #tabNavPanel class="application-content global-background-color">
              <ng-container *ngIf="!(isMobile$ | async); else mobileContainer">
                <router-outlet #o="outlet"></router-outlet>
                <div class="call-center-div" [ngClass]="{'call-center-hidden' : !callPanelOpen}">
                  <dq-call-settings></dq-call-settings>
                </div>
              </ng-container>
              <ng-template #mobileContainer>
                <div class="mobile-router-div">
                  <router-outlet></router-outlet>
                </div>
                <div class="mobile-footer-menu-bar">
                  <ng-container *ngFor="let menu of navigationSideMenu">
                    <ng-container *ngIf="menu.isFooterMenu">
                      <a class="footer-menu" [routerLink]="[menu.link]" routerLinkActive="mobile-footer-menu-actived">
                        <mat-icon>{{ menu.icon }}
                        </mat-icon>
                        <span>{{ menu.label | translate }}</span>
                      </a>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-template>
            </mat-tab-nav-panel>
          </mat-sidenav-container>
        </ng-template>

        <div *ngIf="hasFooter$ | async" class="footer">
          <div class="row">
            <div class="col-sm-12 links">
              <a href="" target="_blank" rel="noopener noreferrer">
                <fa-icon [icon]="['fab','twitter']"></fa-icon>
                <span>Twitter</span>
              </a>
              <a href="" target="_blank" rel="noopener noreferrer">
                <fa-icon [icon]="['fab','youtube']"></fa-icon>
                <span>Youtube</span>
              </a>
              <a href="" target="_blank" rel="noopener noreferrer">
                <fa-icon [icon]="['fab','instagram']"></fa-icon>
                <span>Instagram</span>
              </a>
              <a href="" target="_blank" rel="noopener noreferrer">
                <fa-icon [icon]="['fab','facebook']"></fa-icon>
                <span>Facebook</span>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-12 signature">
              &#169; <span class="year">{{ year }}</span> - Digirealty Technology
              <br class="d-block d-sm-none">
              <a [matTooltip]="'dq.footer.changelog' | translate" matTooltipPosition="before" href="">
                <fa-icon icon="rocket"></fa-icon>
                {{ version }} <span *ngIf="!isProd">[{{ envName }}]</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav-container>
  </div>
</div>

/*eslint-disable*/
// only because we don't have any interface for http requests
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { datadogLogs } from '@datadog/browser-logs';

@Injectable()
export class HttpLoggingInterceptor implements HttpInterceptor {
  pwdFlags = ['password', 'pwd', 'key', 'secret']; // Define the array of sensitive keywords
  pwdRegex = new RegExp(`(${this.pwdFlags.join('|')})\\s*=\\s*[^&]+`, 'gi');
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const started = Date.now();
    let ok: number;

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          ok = event instanceof HttpResponse ? event.status : -1;
        },
        (error: any) => {
          ok = error.status;
          this.logRequest(req, ok, Date.now() - started, error);
        },
        () => {
          this.logRequest(req, ok, Date.now() - started);
        }
      )
    );
  }

  private logRequest(
    req: HttpRequest<any>,
    status: number,
    time: number,
    error?: any
  ) {
    const logMessage = {
      method: req.method,
      url: req.urlWithParams,
      httpStatus: status,
      status: status < 400 ? 'ok' : status < 500 ? 'warn' : 'error',
      duration: `${time} ms`,
      ...(error ? { error: JSON.stringify(error) } : {})
    };

    datadogLogs.logger.log(
      this.formatLogMessage(logMessage),
      this.formatContext(req, logMessage)
    );
  }

  private formatContext(req: HttpRequest<any>, logMessage: any): any {
    const sanitizedHeaders = req.headers.keys().reduce((acc, key) => {
      if (key.toLowerCase() !== 'authorization') {
        // Exclude Authorization header
        acc[key] = req.headers.get(key);
      }
      return acc;
    }, {});
    const sanitizeBody = body => {
      if (typeof body === 'string') {
        const regex = new RegExp(
          `(${this.pwdFlags.join('|')})\\s*=\\s*[^&]+`,
          'gi'
        );
        body = body.replace(regex, 'password=***');
      } else if (typeof body === 'object' && body !== null) {
        for (const pwdFlag of pwdFlags) {
          if (pwdFlag in body) {
            body[pwdFlag] = '***';
          }
        }
        for (const key in body) {
          if (typeof body[key] === 'object' && body[key] !== null) {
            body[key] = sanitizeBody(body[key]);
          }
        }
      }
      return body;
    };

    let sanitizedBody = req.body;

    const pwdFlags = ['password', 'pwd', 'key', 'secret']; // Define the array of sensitive keywords

    if (typeof sanitizedBody === 'string') {
      sanitizedBody = sanitizedBody.replace(this.pwdRegex, '$1=***');
    } else if (typeof sanitizedBody === 'object' && sanitizedBody !== null) {
      sanitizedBody = this.sanitizeObject(sanitizedBody);
    }

    return {
      ...logMessage,
      request: {
        url: req.urlWithParams,
        method: req.method,
        headers: sanitizedHeaders,
        body: sanitizedBody
      }
    };
  }

  private sanitizeObject(obj: any): any {
    const sanitizedObj = { ...obj };

    for (const key in sanitizedObj) {
      if (this.pwdFlags.includes(key.toLowerCase())) {
        sanitizedObj[key] = '***';
      } else if (
        typeof sanitizedObj[key] === 'object' &&
        sanitizedObj[key] !== null
      ) {
        sanitizedObj[key] = this.sanitizeObject(sanitizedObj[key]);
      }
    }

    return sanitizedObj;
  }

  private formatLogMessage(logMessage: any): string {
    return `[HTTP] ${logMessage.method} ${logMessage.url} ${
      logMessage.status
    } in ${logMessage.duration}${
      logMessage.error ? ` - Error: ${logMessage.error}` : ''
    }`;
  }
}

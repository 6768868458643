import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {  MAT_DIALOG_DATA , MatDialog, MatDialogRef} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { State } from 'app/features/client-dialog/client-dialog.state';
import { takeUntil } from 'rxjs/operators';
import { ClientDialogMatDialogService } from 'app/features/client-dialog/client-dialog-mat-dialog.serivce';
import { selectClientDialogIsRentalApplicationCreditCheckSending } from '../../client-dialog-rental-application.selector';
import { CreditCheckForm } from 'app/features/client-dialog/client-dialog-model';
import { selectClientLeadInfo } from '../../../client-dialog-user-general/client-dialog-general.selectors';
import { LeadInfo } from 'app/shared/models/lead-info-models';
import { CANADIAN_PROVINCE, US_STATE } from 'app/shared/models/region-nationality.mode';
import { actionClientDialogApplicationRentalAddNewCreditCheckRequested } from '../../client-dialog-rental-application.action';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-rental-application-report-add-new-credit-check',
  templateUrl: './client-dialog-rental-application-report-add-new-credit-check.component.html',
  styleUrls: ['./client-dialog-rental-application-report-add-new-credit-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogRentalApplicationReportAddNewCreditCheckComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private cdr: ChangeDetectorRef,
    @Inject('dialogData') private dialogData: any,
    public fb: UntypedFormBuilder) {
      this.rentalApplicationId = dialogData.rentalApplicationId;
  }

  unsubscribe$: Subject<void> = new Subject<void>();

  leadInfo: LeadInfo;

  addCreditCheckFormGroup: UntypedFormGroup;
  rentalApplicationId: number;

  countryCode: string;

  spinnerCreateButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Confirm',
    flat: true,    spinnerSize: 18,

    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
  };

  countryOptions = [
    { value: 'CA', label: 'Canada' }
  ];
  usStateOptions = US_STATE;
  provinceOptions = CANADIAN_PROVINCE;

  provinceStateOptions = [];
  maxDate: Date = new Date();

  ngOnInit(): void {

    this.store.select(selectClientLeadInfo).pipe(takeUntil(this.unsubscribe$)).subscribe(leadInfo => {
      this.leadInfo = leadInfo;
    });

    this.addCreditCheckFormGroup = this.fb.group({
      applicantFirstName: ['', Validators.required],
      applicantLastName: ['', Validators.required],
      applicantEmail: ['', [Validators.required, Validators.email]],
      applicantPhoneNumber: ['', Validators.required],
      applicantStreetAddress: ['', Validators.required],
      applicantCity: ['', Validators.required],
      applicantPostalCode: ['', Validators.required],
      applicantState: ['', Validators.required],
      applicantCountry: ['', Validators.required],
      applicantBirthDate: ['', Validators.required]
    });

    this.store.select(selectClientDialogIsRentalApplicationCreditCheckSending).pipe(takeUntil(this.unsubscribe$)).subscribe(isSending => {
      this.spinnerCreateButtonOptions.active = isSending;
      this.cdr.markForCheck();
    });
  }

  onUseLeadInfo() {
    this.addCreditCheckFormGroup.get('applicantFirstName').setValue(this.leadInfo?.firstName);
    this.addCreditCheckFormGroup.get('applicantLastName').setValue(this.leadInfo?.lastName);
    this.addCreditCheckFormGroup.get('applicantEmail').setValue(this.leadInfo?.emails[0]);
    this.addCreditCheckFormGroup.get('applicantPhoneNumber').setValue(this.leadInfo?.phoneNumbers[0]);
  }

  addNewApplication() {
    if(this.addCreditCheckFormGroup.valid && !this.spinnerCreateButtonOptions.active) {
      var creditCheckForm: CreditCheckForm = {
        rentalApplicationId: this.rentalApplicationId,
        applicantFirstName: this.addCreditCheckFormGroup.get('applicantFirstName').value,
        applicantLastName: this.addCreditCheckFormGroup.get('applicantLastName').value,
        applicantEmail: this.addCreditCheckFormGroup.get('applicantEmail').value,
        applicantPhoneNumber: this.addCreditCheckFormGroup.get('applicantPhoneNumber').value,
        applicantStreetAddress: this.addCreditCheckFormGroup.get('applicantStreetAddress').value,
        applicantCity: this.addCreditCheckFormGroup.get('applicantCity').value,
        applicantPostalCode: this.addCreditCheckFormGroup.get('applicantPostalCode').value,
        applicantState: this.addCreditCheckFormGroup.get('applicantState').value,
        applicantCountry: this.addCreditCheckFormGroup.get('applicantCountry').value,
        applicantBirthDate: this.addCreditCheckFormGroup.get('applicantBirthDate').value,
      }

      this.store.dispatch(actionClientDialogApplicationRentalAddNewCreditCheckRequested({
        rentalApplicationId: this.rentalApplicationId,
        creditCheckForm: creditCheckForm
      }));
    }
  }

  onCountryChange(event: any) {
    if (event.value == 'CA') {
      this.provinceStateOptions = this.provinceOptions;
    }
    else if (event.value == 'US') {
      this.provinceStateOptions = this.usStateOptions;
    }
  }

  countryChanged(countrySelected) {
    this.countryCode = countrySelected.iso2;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}


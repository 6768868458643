<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="dialog-container">
    <div class="doc-dialog-title">
        <dq-spinner-button class="action-btn" type="button" (click)="saveChanges()" [options]="spinnerSaveButtonOptions"></dq-spinner-button>
        <div class="right-button-div">
            <div (click)="$event.preventDefault();close()">
                <mat-icon svgIcon="times" class="icon cancel-button"></mat-icon>
            </div>
        </div>
    </div>
    <div class="layout-container" #target>
        <ng-container *ngIf="(isFormEditorLoading$ | async) === false; else formLoading">
            <ng-container *ngIf="document; else noFile">
                <div class="pdf-viewer-container">
                    <ngx-extended-pdf-viewer class="pdf-viewer" [src]="document.documentUrl"
                        (formDataChange)="onFormDataChange($event)" [formData]="formData" [useBdq-rowserLocale]="true"
                        [textLayer]="true" [showHandToolButton]="true" [minifiedJSLibraries]="false">
                    </ngx-extended-pdf-viewer>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #noFile>
            <div class="error-message dq-flex-centered">
                File not available!
            </div>
        </ng-template>
        <ng-template #formLoading>
            <div class="spinner-div dq-flex-centered">
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>
    </div>
</div>
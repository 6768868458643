<div class="client-dialog-note-component">
  <ng-container *ngIf="!(isMobile$ | async); else mobile">
    <div class="desktop-container">
      <mat-form-field appearance="outline">
        <mat-label>Note</mat-label>
        <textarea [formControl]="note" matInput cdkTextareaAutosize cdkAutosizeMinRows="8" cdkAutosizeMaxRows="200"
          placeholder="Add Note" maxlength="4000"></textarea>
      </mat-form-field>
      <dq-spinner-button class="add-button" type="button" (click)="save()" [options]="spinnerSaveButtonOptions">
      </dq-spinner-button>
    </div>
  </ng-container>
  <ng-template #mobile>
    <div class="mobile-container">
      <div class="mobile-header">
        <div class="cancel-div" (click)="$event.preventDefault();close()">
          <mat-icon svgIcon="arrow-left" class="cancel"></mat-icon>
        </div>
        <div class="title-container">
          <mat-icon class="note-icon">note_add</mat-icon>
          <div>Create Note</div>
        </div>
      </div>
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="text-area-container">
              <mat-form-field appearance="outline">
                <mat-label>Note</mat-label>
                <textarea [formControl]="note" matInput cdkTextareaAutosize cdkAutosizeMinRows="8"
                  cdkAutosizeMaxRows="200" placeholder="Add Note" maxlength="4000"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row button-div">
          <div class="col-6" style="text-align: center;">
            <button (click)="close()" mat-button class="generic-button cancel-button">Cancel</button>
          </div>
          <div class="col-6" style="text-align: center;">
            <dq-spinner-button class="generic-button" type="button" (click)="save()"
              [options]="spinnerSaveButtonOptions"></dq-spinner-button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { ClientDialogRentalRequestComponent } from './components/client-dialog-rental-request/client-dialog-rental-request/client-dialog-rental-request.component';
import { ClientDialogRentalApplicationAddNewComponent } from './components/client-dialog-rental-application/components/client-dialog-rental-application-add-new/client-dialog-rental-application-add-new.component';
import { ClientDialogRentalApplicationCancelApplicationComponent } from './components/client-dialog-rental-application/components/client-dialog-rental-application-cancel-application/client-dialog-rental-application-cancel-application.component';
import { ActionDialogComponent, ActionValidationMessage } from '../../shared/components/action-dialog/action-dialog-component.component';
import { ClientDialogRentalApplicationFormAddNewComponent } from './components/client-dialog-rental-application/components/client-dialog-rental-application-form-add-new/client-dialog-rental-application-form-add-new.component';
import { ClientDialogRentalApplicationFormAddNewManualComponent } from './components/client-dialog-rental-application/components/client-dialog-rental-application-form-add-new-manual/client-dialog-rental-application-form-add-new-manual.component';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ClientDialogRentalApplicationCancelRequestComponent } from './components/client-dialog-rental-application/components/client-dialog-rental-application-cancel-request/client-dialog-rental-application-cancel-request.component';
import { ClientDialogRentalApplicationReportAddNewCreditCheckComponent } from './components/client-dialog-rental-application/components/client-dialog-rental-application-report-add-new-credit-check/client-dialog-rental-application-report-add-new-credit-check.component';
import { ClientDialogRentalApplicationSendLinkConfirmDialogComponent } from './components/client-dialog-rental-application/components/client-dialog-rental-application-send-link-confirm-dialog/client-dialog-rental-application-send-link-confirm-dialog.component';
import { ClientDialogRentalApplicationUpdateInfoComponent } from './components/client-dialog-rental-application/components/client-dialog-rental-application-update-info/client-dialog-rental-application-update-info.component';
import { ClientDialogRentalApplicationRentalItemAddComponent } from './components/client-dialog-rental-application/components/client-dialog-rental-application-rental-item-add/client-dialog-rental-application-rental-item-add.component';
import { MultiPurposeDialogComponent } from 'app/shared/components/multi-purpose-dialog/multi-purpose-dialog.component';
import { UnitDescriptionDialogComponent } from 'app/shared/components/unit-description-dialog/unit-description-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ClientDialogMatDialogService {
  constructor(
    private dialog: MatDialog
  ) { }

  rentalApplicationAddNewMatDialogRef: MatDialogRef<MultiPurposeDialogComponent>;
  rentalApplicationCancelMatDialogRef: MatDialogRef<MultiPurposeDialogComponent>;
  rentalRequestCancelMatDialogRef: MatDialogRef<MultiPurposeDialogComponent>;
  rentalApplicationRemoveVerificationReportMatDialogRef: MatDialogRef<ActionDialogComponent>;
  rentalApplicationRemoveFormMatDialogRef: MatDialogRef<ActionDialogComponent>;
  rentalRemoveFileFormMatDialogRef: MatDialogRef<ActionDialogComponent>;
  rentalApplicationRentalRequestStatusMatDialogRef: MatDialogRef<ActionDialogComponent>;
  rentalApplicationResendApplicantEmailMatDialogRef: MatDialogRef<ActionDialogComponent>;
  rentalApplicationResendUploadLinkMatDialogRef: MatDialogRef<MultiPurposeDialogComponent>;
  rentalApplicationAddNewFormMatDialogRef: MatDialogRef<MultiPurposeDialogComponent>;
  rentalRequesMatDialogRef: MatDialogRef<MultiPurposeDialogComponent>;
  rentalApplicationAddNewFormManualMatDialogRef: MatDialogRef<MultiPurposeDialogComponent>;
  rentalApplicationAddNewCreditCheckMatDialogRef: MatDialogRef<MultiPurposeDialogComponent>;
  rentalApplicationUpdateInfoMatDialogRef: MatDialogRef<MultiPurposeDialogComponent>;
  rentalItemRemoveMatDialogRef: MatDialogRef<ActionDialogComponent>;
  rentalRequestAddRentalItemMatDialogRef: MatDialogRef<MultiPurposeDialogComponent>;

  leasingDocSignatureResetDialogConfig: MatDialogRef<ActionDialogComponent>;

  openRentalApplicationAddNew(rentalRequestId: number): void {
    if (this.rentalApplicationAddNewMatDialogRef == undefined || this.rentalApplicationAddNewMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalApplicationAddNewMatDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Add an Applicant',
          component: ClientDialogRentalApplicationAddNewComponent,
          dialogData: {
            rentalRequestId,
          }
        }
      });
    }
  }

  closeRentalApplicationAddNew(): void {
    if (this.rentalApplicationAddNewMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalApplicationAddNewMatDialogRef.close();
    }
  }

  openRentalApplicationAddNewCreditCheck(rentalApplicationId: number): void {
    if (this.rentalApplicationAddNewCreditCheckMatDialogRef == undefined || this.rentalApplicationAddNewCreditCheckMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalApplicationAddNewCreditCheckMatDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
        width: '900px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Add a Credit Check report',
          component: ClientDialogRentalApplicationReportAddNewCreditCheckComponent,
          dialogData: {
            rentalApplicationId,
          }
        }
      });
    }
  }

  closeRentalApplicationAddNewCreditCheck(): void {
    if (this.rentalApplicationAddNewCreditCheckMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalApplicationAddNewCreditCheckMatDialogRef.close();
    }
  }

  openRentalApplicationCancel(rentalApplicationId: number, rentalApplicantFirstName: string, rentalApplicantLastName: string): void {
    if (this.rentalApplicationCancelMatDialogRef == undefined || this.rentalApplicationCancelMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalApplicationCancelMatDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Cancel Rental Application',
          component: ClientDialogRentalApplicationCancelApplicationComponent,
          dialogData: {
            rentalApplicationId,
            rentalApplicantFirstName,
            rentalApplicantLastName
          }
        }
      });
    }
  }

  closeRentalApplicationCancel(): void {
    if (this.rentalApplicationCancelMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalApplicationCancelMatDialogRef.close();
    }
  }

  openRentalRequestCancel(rentalRequestId: number): void {
    if (this.rentalRequestCancelMatDialogRef == undefined || this.rentalRequestCancelMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalRequestCancelMatDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Cancel Rental Request',
          component: ClientDialogRentalApplicationCancelRequestComponent,
          dialogData: {
            rentalRequestId
          }
        }
      });
    }
  }

  closeRentalRequestCancel(): void {
    if (this.rentalRequestCancelMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalRequestCancelMatDialogRef.close();
    }
  }

  openRentalFormRemove(triggerAction: Action, isActionActive: Observable<boolean>, formName: string, title: string): void {
    if (this.rentalApplicationRemoveFormMatDialogRef == undefined || this.rentalApplicationRemoveFormMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalApplicationRemoveFormMatDialogRef = this.dialog.open(ActionDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title,
          notificationText: `Are you sure you want to remove ${formName}? If the document has been filled or signed by the applicant, you will no longer be able to access it.`,
          buttonColor: 'warn',
          spinnerColor: 'accent',
          buttonText: 'Remove',
          triggerAction,
          isActionActive
        }
      });
    }
  }

  closeRentalApplicationFormRemove(): void {
    if (this.rentalApplicationRemoveFormMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalApplicationRemoveFormMatDialogRef.close();
    }
  }

  openRemoveVerificationReport(triggerAction: Action, isActionActive: Observable<boolean>): void {
    if (this.rentalApplicationRemoveVerificationReportMatDialogRef == undefined || this.rentalApplicationRemoveVerificationReportMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalApplicationRemoveVerificationReportMatDialogRef = this.dialog.open(ActionDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Remove Application Form',
          notificationText: `Are you sure you want to remove this verification report?`,
          buttonColor: 'warn',
          spinnerColor: 'accent',
          buttonText: 'Remove',
          triggerAction,
          isActionActive
        }
      });
    }
  }

  closeRemoveVerificationReport(): void {
    if (this.rentalApplicationRemoveVerificationReportMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalApplicationRemoveVerificationReportMatDialogRef.close();
    }
  }

  openResendApplicantEmail(triggerAction: Action, isActionActive: Observable<boolean>): void {
    if (this.rentalApplicationResendApplicantEmailMatDialogRef == undefined || this.rentalApplicationResendApplicantEmailMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalApplicationResendApplicantEmailMatDialogRef = this.dialog.open(ActionDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Resend MyApartment.rent Email',
          notificationText: `Are you sure you want to resend the email to this applicant?`,
          buttonColor: 'warn',
          spinnerColor: 'accent',
          buttonText: 'Send',
          triggerAction,
          isActionActive
        }
      });
    }
  }

  closeResendApplicantEmail(): void {
    if (this.rentalApplicationResendApplicantEmailMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalApplicationResendApplicantEmailMatDialogRef.close();
    }
  }

  openResendUploadLink(isActionActive: Observable<boolean>, rentalApplicationId: number): void {
    if (this.rentalApplicationResendUploadLinkMatDialogRef == undefined || this.rentalApplicationResendUploadLinkMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalApplicationResendUploadLinkMatDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,

        data: {
          title: 'Send Upload Link',
          component: ClientDialogRentalApplicationSendLinkConfirmDialogComponent,
          dialogData: {
            title: 'Send Upload File Link Email',
            placeholder: 'Please insert the message you want the applicant to receive with the upload link below.',
            notificationText: `Are you sure you want to send the email to this applicant?`,
            buttonColor: 'primary',
            spinnerColor: 'accent',
            buttonText: 'Send',
            isActionActive,
            retntalApplicationId: rentalApplicationId
          }
        }
      });
    }
  }

  closeResendUploadLink(): void {
    if (this.rentalApplicationResendUploadLinkMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalApplicationResendUploadLinkMatDialogRef.close();
    }
  }


  openRentalFormFileRemove(triggerAction: Action, isActionActive: Observable<boolean>, fileName: string): void {
    if (this.rentalRemoveFileFormMatDialogRef == undefined || this.rentalRemoveFileFormMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalRemoveFileFormMatDialogRef = this.dialog.open(ActionDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Remove Application File',
          notificationText: `Are you sure you want to remove the file ${fileName}?`,
          buttonColor: 'warn',
          spinnerColor: 'accent',
          buttonText: 'Remove',
          triggerAction,
          isActionActive
        }
      });
    }
  }

  closeRentalFormFileRemove(): void {
    if (this.rentalRemoveFileFormMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalRemoveFileFormMatDialogRef.close();
    }
  }

  openRentalRequestUpdateStatus(triggerAction: Action, resetValidationMessageAction: Action, isActionActive: Observable<boolean>, actionValidationMessages: Observable<ActionValidationMessage>, title: string, notificationText: string): void {
    if (this.rentalApplicationRentalRequestStatusMatDialogRef == undefined || this.rentalApplicationRentalRequestStatusMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalApplicationRentalRequestStatusMatDialogRef = this.dialog.open(ActionDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title,
          notificationText,
          buttonColor: 'primary',
          spinnerColor: 'accent',
          buttonText: 'Confirm',
          triggerAction,
          resetValidationMessageAction,
          isActionActive,
          actionValidationMessages
        }
      });
    }
  }

  closeRentalRequestUpdateStatus(): void {
    if (this.rentalApplicationRentalRequestStatusMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalApplicationRentalRequestStatusMatDialogRef.close();
    }
  }

  openInitRentalRequest(unitId: number, buildingId: number, leadId: number, madeReadyDate: Date, price: number, inventoryFriendlyName: string): void {
    if (this.rentalRequesMatDialogRef == undefined || this.rentalRequesMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalRequesMatDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Start Rental Request',
          component: ClientDialogRentalRequestComponent,
          dialogData: {
            unitId,
            buildingId,
            leadId,
            inventoryFriendlyName,
            madeReadyDate,
            price
          }
        }
      });
    }
  }

  closeInitRentalRequest(): void {
    if (this.rentalRequesMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalRequesMatDialogRef.close();
    }
  }

  openRentalApplicationNewForm(rentalApplicationId: number): void {
    if (this.rentalApplicationAddNewFormMatDialogRef == undefined || this.rentalApplicationAddNewFormMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalApplicationAddNewFormMatDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Add an Application',
          component: ClientDialogRentalApplicationFormAddNewComponent,
          dialogData: {
            formType: 'rentalApplication',
            rentalApplicationId
          }
        }
      });
    }
  }

  openRentalApplicationAdditionalNewForm(rentalApplicationId: number): void {
    if (this.rentalApplicationAddNewFormMatDialogRef == undefined || this.rentalApplicationAddNewFormMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalApplicationAddNewFormMatDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Add Leasing Agreement Addendum Form Document',
          component: ClientDialogRentalApplicationFormAddNewComponent,
          dialogData: {
            formType: 'rentalApplicationAdditionalForm',
            rentalApplicationId
          }
        }
      });
    }
  }

  openRentalRequestNewForm(rentalRequestId: number): void {
    if (this.rentalApplicationAddNewFormMatDialogRef == undefined || this.rentalApplicationAddNewFormMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalApplicationAddNewFormMatDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Add a Schedule',
          component: ClientDialogRentalApplicationFormAddNewComponent,
          dialogData: {
            formType: 'rentalRequest',
            rentalRequestId
          }
        }
      });
    }
  }

  closeRentalNewForm(): void {
    if (this.rentalApplicationAddNewFormMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalApplicationAddNewFormMatDialogRef.close();
    }
  }

  openRentalApplicationNewDocumentManual(rentalApplicationId: number, rentalApplicantFirstName: string, rentalApplicantLastName: string): void {
    if (this.rentalApplicationAddNewFormManualMatDialogRef == undefined || this.rentalApplicationAddNewFormManualMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalApplicationAddNewFormManualMatDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Add a Document',
          component: ClientDialogRentalApplicationFormAddNewManualComponent,
          dialogData: {
            rentalApplicationId,
            rentalApplicantFirstName,
            rentalApplicantLastName
          }
        }
      });
    }
  }

  openRentalRequestNewDocumentManual(rentalRequestId: number): void {
    if (this.rentalApplicationAddNewFormManualMatDialogRef == undefined || this.rentalApplicationAddNewFormManualMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalApplicationAddNewFormManualMatDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Add a Document',
          component: ClientDialogRentalApplicationFormAddNewManualComponent,
          dialogData: {
            rentalRequestId
          }
        }
      });
    }
  }

  closeRentalNewDocumentManual(): void {
    if (this.rentalApplicationAddNewFormManualMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalApplicationAddNewFormManualMatDialogRef.close();
    }
  }

  openRentalApplicationUpdateInfo(rentalApplicationId: number): void {
    if (this.rentalApplicationUpdateInfoMatDialogRef == undefined || this.rentalApplicationUpdateInfoMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalApplicationUpdateInfoMatDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
        width: '900px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Update Rental Application',
          component: ClientDialogRentalApplicationUpdateInfoComponent,
          dialogData: {
            rentalApplicationId
          }
        }
      });
    }
  }

  closeRentalApplicationUpdateInfo(): void {
    if (this.rentalApplicationUpdateInfoMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalApplicationUpdateInfoMatDialogRef.close();
    }
  }

  openRentaItemRmove(triggerAction: Action, isActionActive: Observable<boolean>, itemName: string): void {
    if (this.rentalItemRemoveMatDialogRef == undefined || this.rentalItemRemoveMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalItemRemoveMatDialogRef = this.dialog.open(ActionDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Remove Rental Item',
          notificationText: `Are you sure you want to remove the item ${itemName}?`,
          buttonColor: 'warn',
          spinnerColor: 'accent',
          buttonText: 'Remove',
          triggerAction,
          isActionActive
        }
      });
    }
  }

  closeRentaItemRmove(): void {
    if (this.rentalItemRemoveMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalItemRemoveMatDialogRef.close();
    }
  }

  openRentaItemAdd(rentalRequestId: number): void {
    if (this.rentalRequestAddRentalItemMatDialogRef == undefined || this.rentalRequestAddRentalItemMatDialogRef.getState() != MatDialogState.OPEN) {
      this.rentalRequestAddRentalItemMatDialogRef = this.dialog.open(MultiPurposeDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Add a Rental Item',
          component: ClientDialogRentalApplicationRentalItemAddComponent,
          dialogData: {
            rentalRequestId
          }
        }
      });
    }
  }

  closeRentaItemAdd(): void {
    if (this.rentalRequestAddRentalItemMatDialogRef.getState() != MatDialogState.CLOSED) {
      this.rentalRequestAddRentalItemMatDialogRef.close();
    }
  }

  openLeasingDocSignatureReset(triggerAction: Action, isActionActive: Observable<boolean>): void {
    if (this.leasingDocSignatureResetDialogConfig == undefined || this.leasingDocSignatureResetDialogConfig.getState() != MatDialogState.OPEN) {
      this.leasingDocSignatureResetDialogConfig = this.dialog.open(ActionDialogComponent, {
        width: '700px',
        autoFocus: true,
        panelClass: 'no-padding-dialog-container',
        disableClose: true,
        data: {
          title: 'Signature Reset',
          notificationText: `Are you sure you want to reset the signature?`,
          buttonColor: 'warn',
          spinnerColor: 'accent',
          buttonText: 'Reset',
          triggerAction,
          isActionActive
        }
      });
    }
  }

  closeLeasingDocSignatureReset(): void {
    if (this.leasingDocSignatureResetDialogConfig.getState() != MatDialogState.CLOSED) {
      this.leasingDocSignatureResetDialogConfig.close();
    }
  }

  openUnitDescriptionDailog(desc: string) {
    return this.dialog.open(MultiPurposeDialogComponent, {
      data: {
        title: 'Unit Description',
        component: UnitDescriptionDialogComponent,
        dialogData: {
          desc: desc
        }
      }
    }).afterClosed();
  }
}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../../shared/shared.module';

import { MatFormFieldModule } from '@angular/material/form-field';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';

import { ClientDialogService } from './client-dialog.service';
import { clientDialogReducers, FEATURE_NAME } from './client-dialog.state';
import { ClientDialogEffects } from './client-dialog.effects';
import { ClientDialogComponent } from './client-dialog/client-dialog.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import {
  ClientDialogUserGeneralTagsComponent
} from './components/client-dialog-lead-tag/components/client-dialog-user-general-tags/client-dialog-user-general-tags.component';
import { MomentModule } from 'ngx-moment';
import {
  ClientDialogUserGeneralRepsComponent
} from './components/client-dialog-lead-rep/components/client-dialog-user-general-reps/client-dialog-user-general-reps.component';
import {
  ClientDialogLeadMergeComponent
} from './components/client-dialog-user-general/components/client-dialog-lead-merge/client-dialog-lead-merge.component';
import { MatTableModule } from '@angular/material/table';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RouterModule } from '@angular/router';
import {
  ClientDialogActivitiesComponent
} from './components/client-dialog-activities/client-dialog-activities/client-dialog-activities.component';
import {
  ClientDialogLeadNoteComponent
} from './components/client-dialog-lead-note/client-dialog-lead-note/client-dialog-lead-note.component';
import {
  ClientDialogUserGeneralComponent
} from './components/client-dialog-user-general/client-dialog-user-general/client-dialog-user-general.component';
import { ClientDialogActivityEffects } from './components/client-dialog-activities/client-dialog-activities.effect';
import { ClientDialogLeadNoteEffects } from './components/client-dialog-lead-note/client-dialog-lead-note.effect';
import { ClientDialogGeneralEffects } from './components/client-dialog-user-general/client-dialog-general.effect';
import {
  ClientDialogTaskComponent
} from './components/client-dialog-task/client-dialog-task/client-dialog-task.component';
import { ClientDialogTaskEffects } from './components/client-dialog-task/client-dialog-task.effect';
import {
  ClientDialogTaskCreateDialogComponent
} from './components/client-dialog-task/components/client-dialog-task-create-dialog/client-dialog-task-create-dialog.component';
import {
  ClientDialogTaskEditDialogComponent
} from './components/client-dialog-task/components/client-dialog-task-edit-dialog/client-dialog-task-edit-dialog.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  ClientDialogLeadRepComponent
} from './components/client-dialog-lead-rep/client-dialog-lead-rep/client-dialog-lead-rep.component';
import { ClientDialogTagsEffects } from './components/client-dialog-lead-tag/client-dialog-lead-tag.effect';
import {
  ClientDialogLeadTagComponent
} from './components/client-dialog-lead-tag/client-dialog-lead-tag/client-dialog-lead-tag.component';
import { ClientDialogRepsEffects } from './components/client-dialog-lead-rep/client-dialog-lead-rep.effect';
import {
  ClientDialogBuildingsEffects
} from './components/client-dialog-lead-building/client-dialog-lead-building.effect';
import {
  ClientDialogUserGeneralBuildingsComponent
} from './components/client-dialog-lead-building/components/client-dialog-user-general-buildings/client-dialog-user-general-buildings.component';
import {
  ClientDialogLeadBuildingComponent
} from './components/client-dialog-lead-building/client-dialog-lead-building/client-dialog-lead-building.component';
import {
  ClientDialogPreferencesEffects
} from './components/client-dialog-lead-preference/client-dialog-lead-preference.effect';
import {
  ClientDialogLeadPreferenceComponent
} from './components/client-dialog-lead-preference/client-dialog-lead-preference/client-dialog-lead-preference.component';
import {
  ClientDialogUserGeneralPreferencesComponent
} from './components/client-dialog-lead-preference/components/client-dialog-user-general-preference/client-dialog-user-general-preference.component';
import {
  ClientDialogInventoryComponent
} from './components/client-dialog-inventory/client-dialog-inventory/client-dialog-inventory.component';
import { ClientDialogInventoryEffects } from './components/client-dialog-inventory/client-dialog-inventory.effects';
import { NgxPaginationModule } from 'ngx-pagination';
import { ClientDialogInventoryService } from './components/client-dialog-inventory/client-dialog-inventory.service';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {
  ClientDialogInventoryBookingService
} from './components/client-dialog-inventory/client-dialog-booking-building.service';
import {
  ClientDialogInventoryBookingComponent
} from './components/client-dialog-inventory/components/client-dialog-inventory-booking/client-dialog-inventory-booking/client-dialog-inventory-booking.component';
import {
  ClientDialogBookingBuildingAvailabilityEffects
} from './components/client-dialog-inventory/components/client-dialog-inventory-booking/client-dialog-booking-building-avaliabilty.effects';
import {
  ClientDialogBookingEventCalendarEffects
} from './components/client-dialog-inventory/components/client-dialog-booking-calendar/client-dialog-booking-calendar.effects';
import {
  ClientDialogBookingCalendarComponent
} from './components/client-dialog-inventory/components/client-dialog-booking-calendar/client-dialog-booking-calendar/client-dialog-booking-calendar.component';
import {
  ClientDialogAppointmentComponent
} from './components/client-dialog-appointment/client-dialog-appointment/client-dialog-appointment.component';
import {
  ClientDialogAppointmentService
} from './components/client-dialog-appointment/client-dialog-appointment.service';
import {
  ClientDialogClientAppointmentEffects
} from './components/client-dialog-appointment/client-dialog-appointment.effects';
import {
  ClientDialogCommunicationTemplateEditorComponent
} from './components/client-dialog-communication-template-editor/client-dialog-communication-template-editor.component';
import {
  ClientDialogEmailEditorComponent
} from './components/client-dialog-email-editor/client-dialog-email-editor/client-dialog-email-editor.component';
import { ClientDialogEmailService } from './components/client-dialog-email-editor/client-dialog-email.service';
import {
  CilentDialogLeadTextMessageService
} from './components/client-dialog-lead-quick-text-message/client-dialog-lead-text-message.service';
import {
  ClientDialogLeadQuickTextMessageComponent
} from './components/client-dialog-lead-quick-text-message/client-dialog-lead-quick-text-message/client-dialog-lead-quick-text-message.component';
import {
  ClientDialogLeadTextMessageEffects
} from './components/client-dialog-lead-quick-text-message/client-dialog-lead-quick-text-message.effect';
import {
  ClientDialogEmailEditorEffects
} from './components/client-dialog-email-editor/client-dialog-email-editor.effect';
import {
  ClientDialogPhoneNumberBlockConfirmComponent
} from './components/client-dialog-user-general/components/phone-number-block-confirm/phone-number-block-confirm.component';
import { MatBadgeModule } from '@angular/material/badge';
import {
  ClientDialogApplicationService
} from './components/client-dialog-rental-application/client-dialog-rental-application.service';
import {
  ClientDialogRentalApplicationAddNewComponent
} from './components/client-dialog-rental-application/components/client-dialog-rental-application-add-new/client-dialog-rental-application-add-new.component';
import { ClientDialogMatDialogService } from './client-dialog-mat-dialog.serivce';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import {
  ClientDialogAppointmentCancelDialopgComponent
} from './components/client-dialog-appointment/components/client-appointment-cancel-dialog/client-appointment-cancel-dialog.component';
import {
  ClientDialogClientRentalRequestEffects
} from './components/client-dialog-rental-request/client-dialog-rental-request.effects';
import {
  ClientDialogRentalRequestComponent
} from './components/client-dialog-rental-request/client-dialog-rental-request/client-dialog-rental-request.component';
import {
  ClientDialogRentalApplicationCancelApplicationComponent
} from './components/client-dialog-rental-application/components/client-dialog-rental-application-cancel-application/client-dialog-rental-application-cancel-application.component';
import {
  ClientDialogRentalApplicationFormAddNewComponent
} from './components/client-dialog-rental-application/components/client-dialog-rental-application-form-add-new/client-dialog-rental-application-form-add-new.component';
import {
  ClientDialogRentalApplicationDocViewerComponent
} from './components/client-dialog-rental-application/components/client-dialog-rental-application-doc-viewer/client-dialog-rental-application-doc-viewer.component';
import {
  ClientDialogRentalApplicationDocEditorComponent
} from './components/client-dialog-rental-application/components/client-dialog-rental-application-doc-editor/client-dialog-rental-application-doc-editor.component';
import {
  ClientDialogRentalApplicationFormAddNewManualComponent
} from './components/client-dialog-rental-application/components/client-dialog-rental-application-form-add-new-manual/client-dialog-rental-application-form-add-new-manual.component';
import {
  ClientDialogClientRentalApplicationEffects
} from './components/client-dialog-rental-application/client-dialog-rental-application.effects';
import {
  ClientDialogRentalApplicationComponent
} from './components/client-dialog-rental-application/client-rental-dialog-application/client-dialog-rental-application.component';
import {
  ClientDialogRentalApplicationCancelRequestComponent
} from './components/client-dialog-rental-application/components/client-dialog-rental-application-cancel-request/client-dialog-rental-application-cancel-request.component';
import {
  ClientDialogRentalApplicationReportAddNewCreditCheckComponent
} from './components/client-dialog-rental-application/components/client-dialog-rental-application-report-add-new-credit-check/client-dialog-rental-application-report-add-new-credit-check.component';
import {
  ClientDialogRentalApplicationSendLinkConfirmDialogComponent
} from './components/client-dialog-rental-application/components/client-dialog-rental-application-send-link-confirm-dialog/client-dialog-rental-application-send-link-confirm-dialog.component';
import {
  ClientDialogRentalApplicationUpdateInfoComponent
} from './components/client-dialog-rental-application/components/client-dialog-rental-application-update-info/client-dialog-rental-application-update-info.component';
import {
  ClientDialogRentalApplicationRentalItemAddComponent
} from './components/client-dialog-rental-application/components/client-dialog-rental-application-rental-item-add/client-dialog-rental-application-rental-item-add.component';
import { BookingService } from '../booking/booking.service';

@NgModule({
  imports: [
    CommonModule,
    NgxSliderModule,
    SharedModule,
    RouterModule,
    StoreModule.forFeature(FEATURE_NAME, clientDialogReducers),
    EffectsModule.forFeature([
      ClientDialogEffects,
      ClientDialogGeneralEffects,
      ClientDialogActivityEffects,
      ClientDialogLeadNoteEffects,
      ClientDialogTaskEffects,
      ClientDialogTagsEffects,
      ClientDialogPreferencesEffects,
      ClientDialogRepsEffects,
      ClientDialogBuildingsEffects,
      ClientDialogInventoryEffects,
      ClientDialogBookingBuildingAvailabilityEffects,
      ClientDialogBookingEventCalendarEffects,
      ClientDialogClientAppointmentEffects,
      ClientDialogClientRentalApplicationEffects,
      ClientDialogLeadTextMessageEffects,
      ClientDialogEmailEditorEffects,
      ClientDialogClientRentalRequestEffects
    ]),
    DragDropModule,
    FormsModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatBadgeModule,
    MatExpansionModule,
    MatAutocompleteModule,
    NgxMatIntlTelInputComponent,
    NgxMaterialTimepickerModule,
    NgxPaginationModule,
    ScrollingModule,
    MomentModule,

    MatTableModule,
    ClipboardModule,
    MatGridListModule,
    NgxExtendedPdfViewerModule
  ],
  exports: [],
  declarations: [
    ClientDialogComponent,
    ClientDialogLeadNoteComponent,
    ClientDialogUserGeneralComponent,
    ClientDialogUserGeneralTagsComponent,
    ClientDialogUserGeneralPreferencesComponent,
    ClientDialogUserGeneralRepsComponent,
    ClientDialogUserGeneralBuildingsComponent,
    ClientDialogLeadMergeComponent,
    ClientDialogActivitiesComponent,
    ClientDialogTaskComponent,
    ClientDialogLeadTagComponent,
    ClientDialogLeadPreferenceComponent,
    ClientDialogTaskCreateDialogComponent,
    ClientDialogTaskEditDialogComponent,
    ClientDialogLeadRepComponent,
    ClientDialogLeadBuildingComponent,
    ClientDialogInventoryComponent,
    ClientDialogInventoryBookingComponent,
    ClientDialogBookingCalendarComponent,
    ClientDialogAppointmentComponent,
    ClientDialogAppointmentCancelDialopgComponent,
    ClientDialogCommunicationTemplateEditorComponent,
    ClientDialogEmailEditorComponent,
    ClientDialogLeadQuickTextMessageComponent,
    ClientDialogPhoneNumberBlockConfirmComponent,
    ClientDialogRentalRequestComponent,
    ClientDialogRentalApplicationComponent,
    ClientDialogRentalApplicationAddNewComponent,
    ClientDialogRentalApplicationCancelApplicationComponent,
    ClientDialogRentalApplicationCancelRequestComponent,
    ClientDialogRentalApplicationFormAddNewComponent,
    ClientDialogRentalApplicationDocViewerComponent,
    ClientDialogRentalApplicationDocEditorComponent,
    ClientDialogRentalApplicationFormAddNewManualComponent,
    ClientDialogRentalApplicationReportAddNewCreditCheckComponent,
    ClientDialogRentalApplicationSendLinkConfirmDialogComponent,
    ClientDialogRentalApplicationUpdateInfoComponent,
    ClientDialogRentalApplicationRentalItemAddComponent
  ],
  providers: [
    ClientDialogService,
    ClientDialogInventoryService,
    ClientDialogInventoryBookingService,
    ClientDialogAppointmentService,
    ClientDialogApplicationService,
    ClientDialogEmailService,
    CilentDialogLeadTextMessageService,
    ClientDialogMatDialogService,
    BookingService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ClientDialogModule {

}

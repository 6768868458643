export class BuildingPropertyInventoryFloorPlanInventoryRel {
    floorPlanId: number
    buildingPropertyInventoryId: number;
}

export class BuildingPropertyInventoryFloorPlan {
    id: number
    buildingPropertyInventoryId: number;
    floorPlanUri: string;
    fileType: string;
    friendlyName: string;
    unitType: string;
    description: string;
}

export interface IBuildingMessage {
    id: number;
    buildingId: number;
    name: string;
    isActive: boolean;
    text: string;
    startDateTime: string;
    endDateTime: string;
}

export interface IBuildingRentalFormTemplate {
    id: number;
    friendlyName: string;
    type: string;
    version: number;
    versionLanguage: string;
    isSerialNumberRequired: boolean;
}

export interface IBuildingFormTemplateRelsCont {
    formTemplates: IBuildingRentalFormTemplate[];
    buildingFormTemplates: IBuildingRentalFormTemplateRel[];
}

export interface RentalRequestFormTemplateTypes {
    value: string;
    label: string;
}

export interface IBuildingUnit {
    id: number;
    unitIdentifier: string;
    unitType: string;
    unitBedrooms: number;
    unitBathrooms: number;
    unitSquareFeet: number;
    unitPrice: number;
    depositAmount: number;
    isFurnished: boolean;
    madeReadyDate: Date;
    vacancyDate: Date;
    occupencyStatus: string;
    leasedStatus: string;
    unitEconomicStatus: string;
    externalPaltformId: string;
    buildingPropertyId: number;
    propertyName?: string;
}

export interface IBuildingRentalFormTemplateRel {
    rentalFormTemplateId: number;
    isCreationDefault: boolean;
    defaultParameters?: string;
    isTenant?: boolean;
    isGuarantor?: boolean;
    isOccupant?: boolean;
}

export interface IBuildingPropertyRentalItem {
    id: number;
    friendlyName: string;
    type: string;
    price: number;
    isAvailable?: boolean;
    reservedUntilDate?: string;
    externalIdentifier: string;
    buildingPropertyId: string;
    propertyName?: string;
}

export interface IBuildingPropertyRentalItemToAdd {
    friendlyName: string;
    type: string;
    price: number;
    isAvailable?: boolean;
    reservedUntilDate?: string;
    externalIdentifier: string;
    buildingPropertyId: string;
    propertyName?: string;
}

export interface IBuildingUnitToAdd {
    buildingPropertyId: number;
    unitIdentifier: string;
    unitType: string;
    unitBedrooms: number;
    unitBathrooms: number;
    unitSquareFeet: number;
    unitPrice: number;
    depositAmount: number;
    isFurnished: boolean;
    madeReadyDate: Date;
    vacancyDate: Date;
    occupencyStatus: string;
    leasedStatus: string;
    unitEconomicStatus: string;
    externalPaltformId: string;
}

export interface IBuilding {
    id: number;
    name: string;
    streetAddress: string;
    city: string;
    state: string;
    country: string;
    timeZone: string;
    postalCode: string;
    email: string;
    phoneNumber: string;
    apiPhoneNumber: string;
    automatedTextMessageGroupId: number;
    photos?: string[];
    photoBytes: string;
    photoType: string;
    activationDate?: string;
    photo?: string;
    templateIds: number[];
}

export interface IAutomatedTextMessageGroupOption {
    id: number;
    name: string;
}

export interface IBuildingProperty {
    id: number;
    name: string;
    streetAddress: string;
    timeZone: string;
    state: string;
    city: string;
    postalCode: string;
    country: string;
    type: string;
    numberOfUnits: number;
    externalPlatformId: string;
}

export interface IBuildingPropertyQueryFilter {
    propertyName: string;
}

export interface IBuildingPropertyToAdd {
    buildingId: number;
    name: string;
    streetAddress: string;
    timeZone: string;
    state: string;
    city: string;
    postalCode: string;
    country: string;
    type: string;
    numberOfUnits: number;
    externalPlatformId: string;
}

export interface IBuildingCommunicationSetting {
    textReminderTemplateId: number;
    textReminderDelayInMin: number;
    textReminderVirtualTemplateId: number;
    textReminderVirtualDelayInMin: number;
    emailReminderTemplateId: number;
    emailReminderDelayInMin: number;
    emailReminderVirtualTemplateId: number;
    emailReminderVirtualDelayInMin: number;
    leadIntegrationAutmatedTextMessageTemplateId: number;
    buildingAppointmentEmailTemplateId: number;
    leadIntegrationAutmatedEmailTemplateId: number;
    calendarInviteEmailCcs: string;
}

export interface IBuildingLeadIntegrationProviderMappings {
    leadIntegrationProviderMappings: IBuildingLeadIntegrationProviderMapping[];
    isEdited?: boolean;
    isMappingsLoaded?: boolean;
}

export interface IBuildingLeadIntegrationProviderMapping {
    id?: number;
    providerBuildingIdentifier: string;
    buildingId: number;
    leadIntegrationProviderId: string;
}

export interface IBuildingLeadIntegrationProviders {
    leadIntegrationProviders: IBuildingLeadIntegrationProvider[];
    isProvidersLoaded?: boolean;
}

export interface IBuildingLeadIntegrationProvider {
    id: number;
    friendlyName: string;
    description: string;
    providerIdentifier: string;
    isSystemDefault: boolean;
    isActive: boolean;
}

export interface IBuildingLeadIntegrationProviderData {
    id: number;
    dataId: number;
    accountId: number;
}

export interface IBuildingLeasingSetting {
    isFixDepositAmount: boolean;
    defaultDepositAmount: number;
    depositTransactionType: string;
    isFeeRequired: boolean;
    feeAmount: number;
}

export interface IBuildingCommunicationSettings {
    isLoading: boolean;
    isEditMode: boolean;
    settings: IBuildingCommunicationSetting;
}

export interface IBuildingCommunicationSettings {
    isLoading: boolean;
    isEditMode: boolean;
    settings: IBuildingCommunicationSetting;
}

export const buildingBaseCost: number = 750

export interface IUtilityPayment {
    id: string
    value: string
}

export const UTILITY_PAYMENT_OPTIONS: IUtilityPayment[] = [
    {
        id: 'landlord',
        value: 'Landlord'
    },
    {
        id: 'tenant',
        value: 'Tenant'
    },
    {
        id: 'landlord and tenant',
        value: 'Landlord and Tenant'
    },
]

export interface ICreateUpdateBuildingRequestParams {
    id?: number;
    name: string
    streetAddress: string
    timeZone: string
    state: string
    city: string
    country: string
    email: string
    apiPhoneNumber: string
    automatedTextMessageGroupId: number
    phoneNumber: string
    postalCode: string
    photoBytes: string
    photoType: string
}

export interface IBuildingInitialForm {
    id?: number;
    name: string
    streetAddress: string
    timeZone: string
    state: string
    city: string
    country: string
    email: string
    phoneNumber: string
    apiPhoneNumber: string
    automatedTextMessageGroupId: number
    postalCode: string
    photoBytes: string
    photoType: string
}

export interface IUnitChoiceOptions {
    value: any;
    viewValue: string;
}

export interface IPropertyRentalItemTypeOptions {
    value: any;
    label: string;
}

export const RENTAL_ITEM_TYPES: IPropertyRentalItemTypeOptions[] = [
    { value: 'Locker', label: 'Locker' },
    { value: 'Shed', label: 'Shed' },
    { value: 'OutdoorParking', label: 'Outdoor Parking' },
    { value: 'IndoorParking', label: 'Indoor Parking' },
    { value: 'Other', label: 'Other' },
    { value: 'Unknown', label: 'Unknown' }
]

export interface IAutomatedTextMessageTemplate {
    id: number;
    name: string;
    accountId: number;
    type: string;
}

export interface IAutomatedEmailTemplate {
    id: number;
    name: string;
    accountId: number;
    type: string;
}

export interface ICommunicationTemplate {
    emailTemplates: IAutomatedEmailTemplate[];
    textMessageTemplates: IAutomatedTextMessageTemplate[];
}

export interface IBuildingCommunicationTemplate {
    automatedEmailTemplates: IAutomatedEmailTemplate[];
    automatedTextMessageTemplates: IAutomatedTextMessageTemplate[];
    isLoading: boolean;
}

export interface BuildingPropertyListRequestParams {
    buildingId: number[];
    propertyName: string;
    page: number;
    pageSize: number;
}

export interface IBuildingPropertyUnitQueryFilterBase {
    propertyName: string;
    buildingId: number;
}

export interface IBuildingPropertyRentalItemQueryFilter {
    baseFilters: IBuildingPropertyRentalItemQueryFilterBase;
    advancedFilters: IBuildingPropertyRentalItemFilterAdvanced;
}

export interface IBuildingPropertyRentalItemQueryFilterBase {
    propertyName: string;
    buildingId: number;
}

export interface IBuildingPropertyRentalItemFilterAdvanced {
    buildingPropertyId?: number;
    isAvailable?: boolean;
    type?: string[];
}

export interface IBuildingPropertyUnitFilterAdvanced {
    buildingPropertyId?: number;
    isMadeReady?: boolean;
    madeReadyStartDate?: string;
    madeReadyEndDate?: string;
    vacancyStartDate?: string;
    vacancyEndDate?: string;
    buildingPropertyIds?: number[];
}
export interface IBuildingPropertyUnitQueryFilter {
    baseFilters: IBuildingPropertyUnitQueryFilterBase;
    advancedFilters: IBuildingPropertyUnitFilterAdvanced;
}
export interface BuildingPropertyUnitListRequestParams {
    buildingPropertyId?: number;
    buildingIds: number[];
    isMadeReady?: boolean;
    madeReadyStartDate?: string;
    madeReadyEndDate?: string;
    vacancyStartDate?: string;
    vacancyEndDate?: string;
    propertyName?: string;
    page: number;
    pageSize: number;
    buildingPropertyIds: number[];
    unitIdentifier?: string;
}

export interface BuildingPropertyRentalItemListRequestParams {
    buildingPropertyId: number;
    buildingId: number[];
    isAvailable?: boolean;
    propertyName: string;
    page: number;
    pageSize: number;
    type: string[];
}

export const BUILDING_APPLICATION_EMPLOYMENT_STATUS = {
    EMPLOYED: 'Employed',
    SELF_EMPLOYED: 'SelfEmployed',
    RETIRED: 'Retired',
    UNEMPLOYED: 'Unemployed',
    STUDENT: 'Student',
    INTERNATIONAL_STUDENT: 'InternationalStudent',
    UNKNOWN: 'Unknown'
}

export const BUILDING_APPLICATION_EMPLOYMENT_STATUS_APPLICATION_TYPES = {
    TENANT: 'Tenant',
    OCCUPANT: 'Occupant',
    GUARANTOR: 'Guarantor'
}

export interface BuildingApplicationEmploymentStatus {
    name: string
    value: string
}

export interface BuildingApplicationEmploymentStatusApplicationType {
    name: string
    value: string
}

export const BUILDING_APPLICATION_EMPLOYMENT_STATUS_OPTIONS: BuildingApplicationEmploymentStatus[] = [
    {
        name: 'Employed',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS.EMPLOYED
    },
    {
        name: 'Self Employed',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS.SELF_EMPLOYED
    },
    {
        name: 'Retired',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS.RETIRED
    },
    {
        name: 'Unemployed',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS.UNEMPLOYED
    },
    {
        name: 'Student',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS.STUDENT
    },
    {
        name: 'International Student',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS.INTERNATIONAL_STUDENT
    },
    {
        name: 'Unknown',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS.UNKNOWN
    }
]

export const BUILDING_APPLICATION_EMPLOYMENT_STATUS_APPLICATION_TYPE_OPTIONS: BuildingApplicationEmploymentStatusApplicationType[] = [
    {
        name: 'Tenant',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS_APPLICATION_TYPES.TENANT
    },
    {
        name: 'Occupant',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS_APPLICATION_TYPES.OCCUPANT
    },
    {
        name: 'Guarantor',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS_APPLICATION_TYPES.GUARANTOR
    },
]

export interface BuildingApplicationEmploymentCheckStatus {
    id: number | string
    buildingId: number
    employementStatus: string
    isAssetCheckRequired: boolean
    isIncomeCheckRequired: boolean
    isCriminalCheckRequired: boolean
    isCertnIdentityCheckRequired: boolean
    isPlaidIdentityCheckRequired: boolean
    isSoftCheckRequired: boolean
    isCreditCheckRequired: boolean
    applicationType: string
}

export class AmenityLight {
    id: number
    name: string
    checked?: boolean


    constructor(id: number, name: string, checked?: boolean) {
        this.id = id;
        this.name = name;
        this.checked = false;
    }
}

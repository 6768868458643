<div class="client-dialog-booking-calendar-component">
    <div class="main-container">
        <ng-container *ngIf="!(isLoaded$ | async); else loaded">
            <div class="spinner-div">
                <mat-spinner></mat-spinner>
            </div>
        </ng-container>
        <ng-template #loaded>
            <div class="calendar-header">
                <ng-container *ngIf="(representative$ | async) as representative">
                    <div class="representative-info">
                        <img src="{{getPhotoThumbnail(representative.photoUri)}}" alt="{{representative.fullName}}"
                            title="{{representative.firstName}} {{representative.lastName}}" />
                        <div class="representative-name">
                            <ng-container *ngIf="(leadInfo$ | async) as leadInfo">
                                Booking {{leadInfo.firstName}} {{leadInfo.lastName}} with
                            </ng-container>
                            {{representative.firstName}} {{representative.lastName}}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="(event$ | async) as event">
                    <div class="event-info">
                        <h1 class="event-name">{{event.name}}</h1>
                        <div class="event-duration">
                            <mat-icon>access_time</mat-icon><span>{{event.duration + ' mins'}} </span>
                        </div>
                        <ng-container *ngIf="(timeSlot$ | async).id && (timeSlot$ | async) as timeSlot">
                            <div class="event-date-time-div">
                                <mat-icon>calendar_today</mat-icon>
                                <span>{{timeSlot.label | date: 'h:mm a'}} {{dateTime$ | async | date: 'EEEE, MMMM d,
                                    y'}} ({{timeZone.id}} - {{timeZone.label}})</span>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <div class="calendar-container">
                <div class="container">
                    <ng-container *ngIf="!(timeSlot$ | async).id; else timeSlected">
                        <div class="row">
                            <div class="col-12">
                                <h3>Select a Date & Time</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <dq-calendar (dateSelected)="dateSelected($event)"></dq-calendar>
                            </div>
                            <div class="col-sm-4">
                                <div class="time-zone-selector-div">
                                    <mat-form-field class="time-zone-selector" appearance="outline">
                                        <mat-select [value]="timeZone.id + ' - ' + timeZone.label">
                                            <mat-option *ngFor="let timeZoneOption of (timeZoneOptions$ | async)"
                                                [value]="timeZoneOption.id + ' - ' + timeZoneOption.label"
                                                (click)="changeTimeZone(timeZoneOption)">{{timeZoneOption.id}} -
                                                {{timeZoneOption.label}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <mat-checkbox [ngModel]="isScheduleOverwrite$ | async"
                                    (change)="overWriteScheduleChange($event)"> Overwrite Schedule</mat-checkbox>
                                <div class="time-slot-list">
                                    <ng-container *ngIf="(timeSlotsLoaded$ | async); else timeSlotsLoading">
                                        <ng-container *ngFor="let timeSlot of (timeSlots$ | async)">
                                            <ng-container
                                                *ngIf="(timeIdToConfirm$ | async) != timeSlot.id; else toConfirm">
                                                <div class="time-slot-item"
                                                    (click)="changeTimeIdToConfirm(timeSlot.id)">
                                                    <span>{{timeSlot.label | date: 'h:mm a'}}</span>
                                                </div>
                                            </ng-container>
                                            <ng-template #toConfirm>
                                                <div class="time-confirm-div">
                                                    <button mat-button class="time-button"
                                                        (click)="changeTimeIdToConfirm('')"
                                                       >{{timeSlot.label | date: 'h:mm a'}}</button>
                                                    <button mat-flat-button class="confirm-button"
                                                        (click)="confirmTime(timeSlot)">Confirm</button>
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="(timeSlotsLoaded$ | async) && (timeSlots$ | async).length == 0">
                                        <div class="no-time-slots-div">
                                            Not Available
                                        </div>
                                    </ng-container>
                                    <ng-template #timeSlotsLoading>
                                        <div class="spinner-div">
                                            <mat-spinner></mat-spinner>
                                        </div>
                                    </ng-template>
                                </div>
                                <ng-template #currentSchedule>
                                    <div class="time-slot-list">
                                        <ng-container *ngIf="(timeSlotsLoaded$ | async); else timeSlotsLoading">
                                            <ng-container *ngFor="let timeSlot of (timeSlots$ | async)">
                                                <ng-container
                                                    *ngIf="(timeIdToConfirm$ | async) != timeSlot.id; else toConfirm">
                                                    <div class="time-slot-item"
                                                        (click)="changeTimeIdToConfirm(timeSlot.id)">
                                                        <span>{{timeSlot.label | date: 'h:mm a'}}</span>
                                                    </div>
                                                </ng-container>
                                                <ng-template #toConfirm>
                                                    <div class="time-confirm-div">
                                                        <button mat-button class="time-button"
                                                            (click)="changeTimeIdToConfirm('')"
                                                           >{{timeSlot.label | date: 'h:mm a'}}</button>
                                                        <button mat-flat-button class="confirm-button"
                                                            (click)="confirmTime(timeSlot)">Confirm</button>
                                                    </div>
                                                </ng-template>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="(timeSlotsLoaded$ | async) && (timeSlots$ | async).length == 0">
                                            <div class="no-time-slots-div">
                                                Not Available
                                            </div>
                                        </ng-container>
                                        <ng-template #timeSlotsLoading>
                                            <div class="spinner-div">
                                                <mat-spinner></mat-spinner>
                                            </div>
                                        </ng-template>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #timeSlected>
                        <ng-container>
                            <div class="event-form-div contrast-background-color">
                                <form [formGroup]="eventForm">
                                    <div class="row">
                                        <mat-form-field appearance="outline" class="col-6">
                                            <mat-label>First Name
                                            </mat-label>
                                            <input matInput formControlName="firstName" name="guestName">
                                            <mat-error *ngIf="eventForm.get('firstName').invalid">
                                                First name is required
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" class="col-6">
                                            <mat-label>Last Name
                                            </mat-label>
                                            <input matInput formControlName="lastName" name="guestName">
                                            <mat-error *ngIf="eventForm.get('lastName').invalid">
                                                Last name is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="row">
                                        <mat-form-field appearance="outline" class="col-12">
                                            <mat-label>Email
                                            </mat-label>
                                            <input matInput formControlName="email" name="email">
                                            <mat-error *ngIf="eventForm.get('email').invalid">
                                                Guest email is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="row">
                                        <mat-form-field class="add-rep-form-field" appearance="outline" class="col-12">
                                            <mat-label>Phone</mat-label>
                                            <ngx-mat-intl-tel-input (countryChanged)="countryChanged($event)"
                                                [cssClass]="'custom'" required [preferredCountries]="['ca', 'us']"
                                                placeholder="Enter Phone Number" [enablePlaceholder]="false"
                                                [enableSearch]="true" formControlName="phoneNumber" name="phoneNumber"
                                                #phoneInput></ngx-mat-intl-tel-input>
                                        </mat-form-field>
                                    </div>
                                    <ng-container
                                        *ngIf="(calendarType$ | async) == calendarTypes.callWithRepresentative || (calendarType$ | async) == calendarTypes.showing || (calendarType$ | async) == calendarTypes.virtualBuyer || (calendarType$ | async) == calendarTypes.mortgageConsultation || (calendarType$ | async) == calendarTypes.mortgageVirtualConsultation">
                                        <div class="row">
                                            <mat-form-field appearance="outline" class="col-12">
                                                <mat-label>Persona
                                                </mat-label>
                                                <mat-select formControlName="persona">
                                                    <mat-option *ngFor="let persona of personaOptions"
                                                        [value]="persona.value">{{persona.label}}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="eventForm.get('persona').invalid">
                                                    Persona is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field appearance="outline" class="col-12">
                                                <mat-label>Type
                                                </mat-label>
                                                <mat-select formControlName="leadType" multiple>
                                                    <mat-select-trigger>
                                                        {{eventForm.get('leadType').value ?
                                                        getleadTypeLabel(eventForm.get('leadType').value[0])
                                                        : ''}}
                                                        <span *ngIf="eventForm.get('leadType').value?.length > 1"
                                                            class="">
                                                            (+{{eventForm.get('leadType').value.length -
                                                            1}} {{eventForm.get('leadType').value?.length
                                                            === 2 ? 'other' : 'others'}})
                                                        </span>
                                                    </mat-select-trigger>
                                                    <mat-option *ngFor="let leadType of leadTypeOptions"
                                                        [value]="leadType.value">
                                                        {{leadType.label}}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="eventForm.get('leadType').invalid">
                                                    Type is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field class="add-rep-form-field" appearance="outline"
                                                class="col-12">
                                                <mat-label>Bed</mat-label>
                                                <mat-select formControlName="bed" multiple>
                                                    <mat-select-trigger>
                                                        {{eventForm.get('bed').value ?
                                                        getLeadBedOptionLabel(eventForm.get('bed').value[0])
                                                        : ''}}
                                                        <span *ngIf="eventForm.get('bed').value?.length > 1" class="">
                                                            (+{{eventForm.get('bed').value.length -
                                                            1}} {{eventForm.get('bed').value?.length
                                                            === 2 ? 'other' : 'others'}})
                                                        </span>
                                                    </mat-select-trigger>
                                                    <mat-option *ngFor="let bed of bedOptions" [value]="bed.id">
                                                        {{bed.label}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field class="add-rep-form-field" appearance="outline"
                                                class="col-12">
                                                <mat-label>Bath</mat-label>
                                                <mat-select formControlName="bath" multiple>
                                                    <mat-select-trigger>
                                                        {{eventForm.get('bath').value ?
                                                        getLeadBathOptionLabel(eventForm.get('bath').value[0])
                                                        : ''}}
                                                        <span *ngIf="eventForm.get('bath').value?.length > 1" class="">
                                                            (+{{eventForm.get('bath').value.length -
                                                            1}} {{eventForm.get('bath').value?.length
                                                            === 2 ? 'other' : 'others'}})
                                                        </span>
                                                    </mat-select-trigger>
                                                    <mat-option *ngFor="let bath of bathOptions" [value]="bath.value">
                                                        {{bath.value}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="(calendarType$ | async) == calendarTypes.developerShowing || (calendarType$ | async) == calendarTypes.developerVirtualShowing">
                                        <div class="row">
                                            <mat-form-field class="add-rep-form-field" appearance="outline"
                                                class="col-12">
                                                <mat-label>Move-in date</mat-label>
                                                <input matInput [min]="minDate" [matDatepicker]="picker"
                                                    (click)="picker.open()" formControlName="moveInDate" required>
                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field class="add-rep-form-field" appearance="outline"
                                                class="col-12">
                                                <mat-label>Bed</mat-label>
                                                <mat-select formControlName="bed" multiple>
                                                    <mat-select-trigger>
                                                        {{eventForm.get('bed').value ?
                                                        getLeadBedOptionLabel(eventForm.get('bed').value[0])
                                                        : ''}}
                                                        <span *ngIf="eventForm.get('bed').value?.length > 1" class="">
                                                            (+{{eventForm.get('bed').value.length -
                                                            1}} {{eventForm.get('bed').value?.length
                                                            === 2 ? 'other' : 'others'}})
                                                        </span>
                                                    </mat-select-trigger>
                                                    <mat-option *ngFor="let bed of bedOptions" [value]="bed.value">
                                                        {{bed.label}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field class="add-rep-form-field" appearance="outline"
                                                class="col-12">
                                                <mat-label>Bath</mat-label>
                                                <mat-select formControlName="bath" multiple>
                                                    <mat-select-trigger>
                                                        {{eventForm.get('bath').value ?
                                                        getLeadBathOptionLabel(eventForm.get('bath').value[0])
                                                        : ''}}
                                                        <span *ngIf="eventForm.get('bath').value?.length > 1" class="">
                                                            (+{{eventForm.get('bath').value.length -
                                                            1}} {{eventForm.get('bath').value?.length
                                                            === 2 ? 'other' : 'others'}})
                                                        </span>
                                                    </mat-select-trigger>
                                                    <mat-option *ngFor="let bath of bathOptions" [value]="bath.value">
                                                        {{bath.label}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field class="add-rep-form-field" appearance="outline"
                                                class="col-12">
                                                <mat-label>Parking</mat-label>
                                                <mat-select formControlName="parking">
                                                    <mat-option *ngFor="let parking of parkingOptions"
                                                        [value]="parking.value">
                                                        {{parking.label}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field class="add-rep-form-field" appearance="outline"
                                                class="col-12">
                                                <mat-label>Pet</mat-label>
                                                <mat-select formControlName="pet" multiple>
                                                    <mat-select-trigger>
                                                        {{eventForm.get('pet').value ?
                                                        getLeadPetOptionLabel(eventForm.get('pet').value[0])
                                                        : ''}}
                                                        <span *ngIf="eventForm.get('pet').value?.length > 1" class="">
                                                            (+{{eventForm.get('pet').value.length -
                                                            1}} {{eventForm.get('pet').value?.length
                                                            === 2 ? 'other' : 'others'}})
                                                        </span>
                                                    </mat-select-trigger>
                                                    <mat-option *ngFor="let pet of petOptions" [value]="pet.value">
                                                        {{pet.label}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field class="add-rep-form-field" appearance="outline"
                                                class="col-12">
                                                <mat-label>Accessibility</mat-label>
                                                <mat-select formControlName="accessibility">
                                                    <mat-option *ngFor="let option of accessibilityOptions"
                                                        [value]="option.value">
                                                        {{option.label}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                    <div class="row">
                                        <mat-form-field appearance="outline" class="col-12">
                                            <mat-label>Budget
                                            </mat-label>
                                            <input matInput formControlName="budget" name="budget" cdkNumberDotComma>
                                            <mat-error *ngIf="eventForm.get('budget').invalid">
                                                Budget is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <ng-container
                                        *ngIf="(calendarType$ | async) == calendarTypes.callWithRepresentative || (calendarType$ | async) == calendarTypes.showing || (calendarType$ | async) == calendarTypes.virtualBuyer || (calendarType$ | async) == calendarTypes.mortgageConsultation || (calendarType$ | async) == calendarTypes.mortgageVirtualConsultation">
                                        <div class="row">
                                            <mat-form-field appearance="outline" class="col-12">
                                                <mat-label>Lead Score
                                                </mat-label>
                                                <mat-select formControlName="leadScore">
                                                    <mat-option *ngFor="let leadScore of leadScoreOptions"
                                                        [value]="leadScore.value">{{leadScore.label}}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="eventForm.get('leadScore').invalid">
                                                    Lead Score is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field appearance="outline" class="col-12">
                                                <mat-label>Timeline
                                                </mat-label>
                                                <mat-select formControlName="timeline">
                                                    <mat-option *ngFor="let timeline of timelineOptions"
                                                        [value]="timeline.value">{{timeline.label}}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="eventForm.get('timeline').invalid">
                                                    Timeline is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field appearance="outline" class="col-12">
                                                <mat-label>Appointment Source
                                                </mat-label>
                                                <mat-select formControlName="appointmentSource">
                                                    <mat-option
                                                        *ngFor="let appointmentSource of appointmentSourceOptions"
                                                        [value]="appointmentSource.value">{{appointmentSource.label}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="eventForm.get('appointmentSource').invalid">
                                                    Appointment Source is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field appearance="outline" class="col-12">
                                                <mat-label>Appointment Method
                                                </mat-label>
                                                <mat-select formControlName="appointmentMethod">
                                                    <mat-option
                                                        *ngFor="let appointmentMethod of appointmentMethodOptions"
                                                        [value]="appointmentMethod.value">{{appointmentMethod.label}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="eventForm.get('appointmentMethod').invalid">
                                                    Appointment method is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="row">
                                            <mat-form-field appearance="outline" class="col-12">
                                                <mat-label>Number of Contacts
                                                </mat-label>
                                                <input matInput formControlName="numberOfContacts" type="number" min="0"
                                                    max="20">
                                                <mat-error *ngIf="eventForm.get('numberOfContacts').invalid">
                                                    Number of Contacts is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="(calendarType$ | async) == calendarTypes.developerShowing || (calendarType$ | async) == calendarTypes.developerVirtualShowing">
                                        <div class="row">
                                            <mat-form-field class="add-rep-form-field" appearance="outline"
                                                class="col-12">
                                                <mat-label>Property</mat-label>
                                                <mat-select formControlName="propertyId">
                                                    <ng-container
                                                        *ngIf="buildingProperties$ | async as buildingProperties">
                                                        <mat-option *ngFor="let buildingPropertie of buildingProperties"
                                                            (click)="changeBookingFormBuildingAddress(buildingPropertie.streetAddress,buildingPropertie.city,buildingPropertie.state,buildingPropertie.postalCode)"
                                                            [value]="buildingPropertie.id">{{buildingPropertie.name}}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field appearance="outline" class="col-12">
                                                <mat-label>Building Address
                                                </mat-label>
                                                <input matInput formControlName="buildingAddress"
                                                    name="buildingAddress">
                                                <mat-error *ngIf="eventForm.get('buildingAddress').invalid">
                                                    Building address is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="(calendarType$ | async) == calendarTypes.mortgageConsultation || (calendarType$ | async) == calendarTypes.mortgageVirtualConsultation">
                                        <div class="row">
                                            <mat-form-field class="add-rep-form-field" appearance="outline"
                                                class="col-12">
                                                <mat-label>Is Pre Approved</mat-label>
                                                <mat-select formControlName="isPreApproved">
                                                    <mat-option value="true">Yes</mat-option>
                                                    <mat-option value="false">No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!isEditMode">
                                        <div class="row">
                                            <mat-form-field class="add-rep-form-field" appearance="outline"
                                                class="col-12">
                                                <mat-label>Lead Source</mat-label>
                                                <mat-select formControlName="leadSource">
                                                    <mat-select-trigger>
                                                        {{eventForm.get('leadSource').value ?
                                                        getLeadsourceLabel(eventForm.get('leadSource').value) : ''}}
                                                    </mat-select-trigger>
                                                    <ng-container *ngIf="leadSources$ | async as leadSources">
                                                        <mat-option *ngFor="let leadSource of leadSources"
                                                            [value]="leadSource.id">{{leadSource.shortName}}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                    <div class="row">
                                        <mat-form-field class="add-rep-form-field" appearance="outline" class="col-12">
                                            <mat-label>Language</mat-label>
                                            <mat-select formControlName="language">
                                                <mat-option *ngFor="let language of languageOptions"
                                                    [value]="language.id">{{language.label}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="row">
                                        <mat-form-field appearance="outline" class="col-12">
                                            <mat-label>Please share anything that will help prepare for our meeting.
                                            </mat-label>
                                            <textarea matInput formControlName="eventDesc"
                                                class="event-desc-textarea"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div class="row">
                                        <div class="spinner-button-div">
                                            <dq-spinner-button class="submit-button" (click)="save()" type="button"
                                                [options]="spinnerButtonOptions"></dq-spinner-button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-container>
                    </ng-template>
                </div>
            </div>
        </ng-template>
    </div>
</div>

/* eslint-disable */
// Only since Datadogs logs are triggered by console logs, we need to disable the eslint rule for console
import { datadogLogs } from '@datadog/browser-logs';
import { environment } from '../../../environments/environment';

export default function initDatadog() {
  datadogLogs.init({
    ...environment.datadog,
    service: environment.appName,
    env: environment.envName,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100
  });
  overrideConsoleMethods();
}

function overrideConsoleMethods() {
  const actions = [
    { console: ['log', 'info', 'table'], datadog: 'info' },
    { console: 'error', datadog: 'error' },
    { console: 'debug', datadog: 'debug' },
    { console: 'warn', datadog: 'warn' }
  ];

  actions.forEach(action => {
    for (const actionConsole of [action.console].flat()) {
      const originalConsoleMethod = console[actionConsole]?.bind(console);

      console[actionConsole] = (...args: any[]) => {
        originalConsoleMethod(...args);
        for (const actionDatadog of [action.datadog].flat()) {
          const { stackTrace, sourceLocation } = getSourceLocation();

          // Send log to Datadog
          datadogLogs.logger[actionDatadog](formatArgs(args), {
            args,
            sourceLocation,
            stackTrace: stackTrace.replace(/^Error\n/, '')
          });
        }
      };
    }
  });
}

function getSourceLocation(): { stackTrace: string; sourceLocation: string } {
  const error = new Error();
  const stackTrace = error.stack || 'No stack trace available';
  const sourceLocation = extractSourceLocation(stackTrace);
  return { stackTrace, sourceLocation };
}

function extractSourceLocation(stackTrace: string): string {
  // Extract source location from the stack trace
  const stackLines = stackTrace.split('\n');
  // Example for a typical Chrome stack trace format
  // "at <function> (<file>:<line>:<column>)"
  // This regex might need to be adjusted based on the stack trace format
  const locationMatch = stackLines[1]?.match(/\((.*):(\d+):(\d+)\)/);
  if (locationMatch) {
    const [_, file, line, column] = locationMatch;
    return `File: ${file}, Line: ${line}, Column: ${column}`;
  }
  return 'Unknown source location';
}

function formatArgs(args: any[]): string {
  return args
    .map(arg =>
      typeof arg === 'object'
        ? stringifyWithDepthLimit(arg, 4)
        : arg?.toString()
    )
    .join(' - ');
}

function stringifyWithDepthLimit(obj: any, maxDepth: number = 1): string {
  const visited = new WeakMap();

  const replacer = (key: string, value: any, depth: number = 0) => {
    if (typeof value === 'object' && value !== null) {
      if (visited.has(value)) {
        return `[Circular -> ${visited.get(value)}]`;
      }

      visited.set(value, key || 'root');

      if (depth >= maxDepth) {
        return '[Object]';
      }

      // Increase depth for nested objects
      const objCopy: any = Array.isArray(value) ? [] : {};
      for (const k in value) {
        if (Object.prototype.hasOwnProperty.call(value, k)) {
          objCopy[k] = replacer(k, value[k], depth + 1);
        }
      }
      return objCopy;
    }
    return value;
  };

  return JSON.stringify(replacer('', obj), null, 2); // Format the JSON output with indentation
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';

import { State } from 'app/features/client-dialog/client-dialog.state';
import { debounceTime, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ClientDialogMatDialogService } from 'app/features/client-dialog/client-dialog-mat-dialog.serivce';
import { selectClientDialogIsRentalApplicationInfoUpdating, selectClientDialogRentalApplication, selectClientDialogRentalApplicationLeadInfo } from '../../client-dialog-rental-application.selector';
import { RentalApplication, RentalApplicationInfoUpdateForm } from 'app/features/client-dialog/client-dialog-model';
import { CANADIAN_PROVINCE, US_STATE } from 'app/shared/models/region-nationality.mode';
import { actionClientDialogApplicationGetLeadInfoByLeadIdRequested, actionClientDialogApplicationRentalInfoUpdateRequested, actionClientDialogApplicationUpdateApplicantLeadInfo } from '../../client-dialog-rental-application.action';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';
import { LeadInfo } from 'app/shared/models/lead-info-models';
import { LeadIndex } from 'app/shared/models/lead.model';
import { BookingService } from 'app/features/booking/booking.service';

@Component({
  selector: 'dq-client-dialog-rental-application-update-info',
  templateUrl: './client-dialog-rental-application-update-info.component.html',
  styleUrls: ['./client-dialog-rental-application-update-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogRentalApplicationUpdateInfoComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private clientDialogMatDialogService: ClientDialogMatDialogService,
    private cdr: ChangeDetectorRef,
    @Inject('dialogData') private dialogData: any,
    private bookingService: BookingService,
    public fb: UntypedFormBuilder) {
    this.rentalApplicationId = dialogData.rentalApplicationId;
  }

  unsubscribe$: Subject<void> = new Subject<void>();

  applicationUpdateForm: UntypedFormGroup;
  rentalApplicationId: number;
  rentalApplicationLeadInfo$: Observable<LeadInfo>;
  rentalApplicationLeadInfo: LeadInfo;
  rentalApplicationInfo: RentalApplication;
  countryCode: string;

  spinnerCreateButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Confirm',
    flat: true, spinnerSize: 18,

    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
  };

  countryOptions = [
    { value: 'CA', label: 'Canada' }
  ];

  usStateOptions = US_STATE;
  provinceOptions = CANADIAN_PROVINCE;

  provinceStateOptions = [];
  maxDate: Date = new Date();

  searchControl = new UntypedFormControl();
  leadSearchOptions$: Observable<LeadIndex[]>;
  searchDivVisible: boolean = false;
  ngOnInit(): void {
    this.applicationUpdateForm = this.fb.group({
      applicantFirstName: ['', Validators.required],
      applicantLastName: ['', Validators.required],
      applicantEmail: ['', [Validators.required, Validators.email]],
      applicantPhoneNumber: ['', Validators.required],
      applicantStreetAddress: ['', Validators.required],
      applicantCity: ['', Validators.required],
      applicantPostalCode: ['', Validators.required],
      applicantState: ['', Validators.required],
      applicantCountry: ['', Validators.required],
      applicantBirthDate: ['', Validators.required]
    });

    this.store.select(selectClientDialogIsRentalApplicationInfoUpdating).pipe(takeUntil(this.unsubscribe$)).subscribe(isSending => {
      this.spinnerCreateButtonOptions.active = isSending;
      this.cdr.markForCheck();
    });

    this.rentalApplicationLeadInfo$ = this.store.select(selectClientDialogRentalApplicationLeadInfo);
    this.rentalApplicationLeadInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(leadInfo => {
      this.rentalApplicationLeadInfo = leadInfo;
    });

    this.store.select(selectClientDialogRentalApplication).pipe(takeUntil(this.unsubscribe$)).subscribe(rentalApplication => {
      if (rentalApplication.id > 0) {
        this.setApplicationInfo(rentalApplication)
        this.rentalApplicationInfo = rentalApplication;
      }
    });

    this.leadSearchOptions$ = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        // delay emits
        debounceTime(300),
        // use switch map so as to cancel previous subscribed events, before creating new once
        switchMap(value => {
          if (value !== '' && (typeof value === 'string')) {
            return this.bookingService.getLeadsBySearchKeyWord(value, 1, 20).pipe(
              map(result => result.data.objectsList));
          } else {
            // if no value is present, return null
            return of(null);
          }
        })
      );

    this.store.dispatch(actionClientDialogApplicationGetLeadInfoByLeadIdRequested({ leadId: this.rentalApplicationInfo.leadId }));
  }

  setApplicationInfo(application: RentalApplication) {
    this.applicationUpdateForm.get('applicantFirstName').setValue(application?.applicantFirstName);
    this.applicationUpdateForm.get('applicantLastName').setValue(application?.applicantLastName);
    this.applicationUpdateForm.get('applicantEmail').setValue(application?.applicantEmail);
    this.applicationUpdateForm.get('applicantPhoneNumber').setValue(application?.applicantPhoneNumber);
    this.applicationUpdateForm.get('applicantCountry').setValue(application?.applicantCountry);
    this.onCountryChange({
      value: application?.applicantCountry
    });
    this.applicationUpdateForm.get('applicantStreetAddress').setValue(application?.applicantStreetAddress);
    this.applicationUpdateForm.get('applicantCity').setValue(application?.applicantCity);
    this.applicationUpdateForm.get('applicantPostalCode').setValue(application?.applicantPostalCode);
    this.applicationUpdateForm.get('applicantState').setValue(application?.applicantState);
    this.applicationUpdateForm.get('applicantBirthDate').setValue(new Date(application?.applicantBirthDate));
  }

  updateApplication() {
    if (this.applicationUpdateForm.valid && !this.spinnerCreateButtonOptions.active) {
      var updateInfo: RentalApplicationInfoUpdateForm = {
        rentalApplicationId: this.rentalApplicationId,
        applicantFirstName: this.applicationUpdateForm.get('applicantFirstName').value,
        applicantLastName: this.applicationUpdateForm.get('applicantLastName').value,
        applicantEmail: this.applicationUpdateForm.get('applicantEmail').value,
        applicantPhoneNumber: this.applicationUpdateForm.get('applicantPhoneNumber').value,
        applicantStreetAddress: this.applicationUpdateForm.get('applicantStreetAddress').value,
        applicantCity: this.applicationUpdateForm.get('applicantCity').value,
        applicantPostalCode: this.applicationUpdateForm.get('applicantPostalCode').value,
        applicantState: this.applicationUpdateForm.get('applicantState').value,
        applicantCountry: this.applicationUpdateForm.get('applicantCountry').value,
        applicantBirthDate: this.applicationUpdateForm.get('applicantBirthDate').value,
        leadId: this.rentalApplicationLeadInfo?.id
      }

      this.store.dispatch(actionClientDialogApplicationRentalInfoUpdateRequested({
        rentalApplicationId: this.rentalApplicationId,
        updateInfo: updateInfo
      }));
    }
  }

  onCountryChange(event: any) {
    if (event.value == 'CA') {
      this.provinceStateOptions = this.provinceOptions;
    }
    else if (event.value == 'US') {
      this.provinceStateOptions = this.usStateOptions;
    }
  }

  countryChanged(countrySelected) {
    this.countryCode = countrySelected.iso2;
  }

  selectSearchOption(lead: LeadIndex) {
    this.store.dispatch(actionClientDialogApplicationUpdateApplicantLeadInfo(
      {
        leadId: lead.id,
        firstName: lead.firstName,
        lastName: lead.lastName,
        emails: lead.emails,
        phoneNumbers: lead.phoneNumbers,
      }));
  }


  toggleSearchDiv(): void {
    this.searchDivVisible = !this.searchDivVisible;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}


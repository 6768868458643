import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/core.state';

import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { PhoneNumberBlockInfo } from 'app/shared/models/phone-number-block-info.model';
import { selectclientDialogLeadPhoneNumberBlockIsUpdating, selectclientDialogLeadPhoneNumberBlockIsUpdatedSuccessfully, selectClientLeadId } from '../../client-dialog-general.selectors';
import { actionClientDialogLeadPhoneNumberBlockRequested, actionClientDialogLeadPhoneNumberBlockStateReset, actionClientDialogLeadPhoneNumberUnBlockRequested } from '../../client-dialog-general.action';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-phone-number-block-confirm',
  templateUrl: './phone-number-block-confirm.component.html',
  styleUrls: ['./phone-number-block-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogPhoneNumberBlockConfirmComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<ClientDialogPhoneNumberBlockConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PhoneNumberBlockInfo,
    private cdr: ChangeDetectorRef,
  ) {
    this.updateType = data.updateType
    this.spinnerSaveButtonOptions.text = data.updateType
    this.content = `Do you want to ${data.updateType} ${data.phoneNumber} ?`
    this.phoneNumber = data.phoneNumber
    this.phoneNumberId = data.phoneNumberId
    this.title = `${data.updateType} Confirm`
  }

  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Update',
    flat: true,
    buttonColor: 'warn',
    spinnerColor: 'accent',
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  unsubscribe: Subject<void> = new Subject();
  phoneNumber: string
  phoneNumberId: number
  title: string = 'Update Confirm'
  content: string = ''
  updateType: string = ''
  isPhoneNumberBlockUpdating$: Observable<boolean>
  isPhoneNumberBlockUpdatedSuccessfully$: Observable<boolean>
  blockReason: UntypedFormControl = new UntypedFormControl('', [Validators.required])

  leadId$: Observable<number>
  leadId: number

  ngOnInit(): void {
    this.leadId$ = this.store.pipe(select(selectClientLeadId));
    this.leadId$.pipe(takeUntil(this.unsubscribe)).subscribe(leadId => {
      if (leadId > 0) {
        this.leadId = leadId
      }
    })

    this.isPhoneNumberBlockUpdating$ = this.store.pipe(select(selectclientDialogLeadPhoneNumberBlockIsUpdating));
    this.isPhoneNumberBlockUpdatedSuccessfully$ = this.store.pipe(select(selectclientDialogLeadPhoneNumberBlockIsUpdatedSuccessfully));

    this.isPhoneNumberBlockUpdating$.pipe(takeUntil(this.unsubscribe)).subscribe(isPhoneNumberBlockUpdating => {
      this.spinnerSaveButtonOptions.active = isPhoneNumberBlockUpdating
      this.cdr.markForCheck()
    })

    this.isPhoneNumberBlockUpdatedSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isPhoneNumberBlockUpdatedSuccessfully => {
      if (isPhoneNumberBlockUpdatedSuccessfully) {
        this.dialogRef.close(true);
      }
    })
  }

  confirm() {
    if (this.spinnerSaveButtonOptions.active) {
      return
    }
    this.blockReason.markAsTouched();
    if (this.updateType == 'Block' && this.blockReason.invalid) {
      return
    }
    if (this.updateType == 'Block') {
      this.store.dispatch(actionClientDialogLeadPhoneNumberBlockRequested({ leadId: this.leadId, phoneNumber: this.phoneNumber, reason: this.blockReason.value }))
    }
    if (this.updateType == 'Unblock') {
      this.store.dispatch(actionClientDialogLeadPhoneNumberUnBlockRequested({ leadId: this.leadId, phoneNumber: this.phoneNumber }))
    }


  }

  cancel() {
    this.dialogRef.close(false);
  }

  close() {
    this.dialogRef.close(true);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
    this.store.dispatch(actionClientDialogLeadPhoneNumberBlockStateReset())
  }
}
